import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '@/context/AuthContext'
import { useForm } from 'react-hook-form'
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom'

import RouteBase from '@/routes/RouteBase'
import LogoImg from '@/assets/img/favicon.webp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCheckCircle,
    faInfoCircle,
    faSpinner,
} from '@fortawesome/free-solid-svg-icons'

import GoogleLogo from '@/assets/img/google-logo.png'

const Login = () => {
    const { t } = useTranslation()
    const { user, error, login, googleLogin, pending, setPending } =
        useContext(AuthContext)

    const navigate = useNavigate()
    const location = useLocation()
    let { extra_params } = useParams()

    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm()

    const ApiError = error

    useEffect(() => {
        if (user.isAuthenticated) {
            const { from } = location.state || {
                from: { pathname: '/dashboard' },
            }
            navigate(from)
        }
    }, [user.isAuthenticated, navigate, location])

    const handleLogin = (data) => {
        setPending(true)
        if (!data.email || !data.password) return
        login(data.email, data.password)
    }
    const handleGoogleLogin = () => {
        setPending(true)
        googleLogin()
    }

    const NewSignup = () => {
        return (
            <>
                {location.state?.newSignup && (
                    <div className="mx-auto mt-2 mb-2 md:mt-10 md:mb-none alert shadow flex flex-row bg-green-100 bg-opacity-60 font-medium w-[90%] md:w-[50%]">
                        <div>
                            <FontAwesomeIcon
                                icon={faCheckCircle}
                                className="text-green-800 mr-3"
                            />
                            <span>
                                Η εγγραφή σας ολοκληρώθηκε με επιτυχία. Σας έχει
                                αποσταλεί ένα email για την επιβεβαίωση του
                                λογαριασμού σας. Μόλις επιβεβαιώσετε το
                                λογαριασμό σας, μπορείτε να συνδεθείτε.
                                <br></br>
                                Αν δε λάβατε το μήνυμα επιβεβαίωσης, ζητήστε να{' '}
                                <a
                                    className="link text-blue-900 font-medium ml-auto"
                                    onClick={() => {
                                        navigate(
                                            '/resend-verification-email/',
                                            {
                                                replace: true,
                                                state: {
                                                    email: location.state
                                                        ?.signupEmail,
                                                },
                                            }
                                        )
                                    }}
                                >
                                    αποσταλεί ξανά
                                </a>
                            </span>
                        </div>
                    </div>
                )}
            </>
        )
    }

    const EmailVerified = () => {
        return (
            <>
                {extra_params && extra_params === 'email-verified' && (
                    <div className="mx-auto mt-2 mb-2 md:mt-10 md:mb-none alert shadow bg-green-100 bg-opacity-60 font-medium w-[90%] md:w-[50%] ">
                        <div>
                            <FontAwesomeIcon
                                icon={faCheckCircle}
                                className="text-green-800 mr-3"
                            />
                            <span>
                                Η διεύθυνση e-mail σας επιβεβαιώθηκε επιτυχώς.
                                Συνεχίστε παρακάτω για να συνδεθείτε
                            </span>
                        </div>
                    </div>
                )}
            </>
        )
    }

    const StateMessages = () => {
        if (location.state?.newSignup) return null
        return (
            <>
                {location.state?.message ? (
                    <div
                        className={`ml-auto alert shadow-lg mb-6 mr-4 font-medium w-72 bg-blue-100`}
                    >
                        <div>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            <span>{location.state.message}</span>
                        </div>
                    </div>
                ) : null}
            </>
        )
    }

    const SubmitButtonComponent = () => {
        return (
            <button type="submit" className="mt-4 btn btn-outline mr-auto">
                {pending && (
                    <FontAwesomeIcon
                        icon={faSpinner}
                        className="animate-spin mr-2"
                    />
                )}
                {t('Σύνδεση')}
            </button>
        )
    }

    const SignInWithGoogleButton = () => {
        return (
            <button
                className="text-medium rounded-lg shadow py-2 px-6 bg-white hover:bg-gray-100 border border-gray-300 active:ring ring-blue-100 transition-all duration-300 flex flex-row gap-1 items-center"
                onClick={handleGoogleLogin}
            >
                {pending ? (
                    <FontAwesomeIcon
                        icon={faSpinner}
                        className="animate-spin mr-2"
                    />
                ) : (
                    <img src={GoogleLogo} alt="" className="h-8" />
                )}
                {t('Σύνδεση με Google')}
            </button>
        )
    }

    return (
        <RouteBase classNames="flex flex-col content-center items-center h-full">
            <div className="h-full w-full flex flex-col">
                <NewSignup />
                <EmailVerified />

                <div className="m-auto rounded-lg bg-blue-100 bg-opacity-20 border border-blue-100 p-4 flex  flex-col gap-3 ">
                    <div className="h-content flex flex-col md:flex-row ">
                        <img
                            src={LogoImg}
                            alt=""
                            width="150"
                            className="m-auto"
                        />

                        <div className="divider lg:divider-horizontal"></div>

                        <form
                            onSubmit={handleSubmit(handleLogin)}
                            className="flex flex-col p-5 px-7 "
                        >
                            <h1 className=" text-xl  my-4">{t('Είσοδος')} </h1>

                            <div className="form-group mb-5">
                                <label htmlFor="email" className="font-medium">
                                    e-mail
                                </label>
                                <input
                                    {...register('email', {
                                        required: 'This field is required.',
                                    })}
                                    type="text"
                                    placeholder="E-mail"
                                    className="w-full input input-bordered input-primary"
                                    id="email"
                                    name="email"
                                />
                                {errors.email && (
                                    <small className="text-error">
                                        {errors.email.message}
                                    </small>
                                )}
                                {ApiError?.email && (
                                    <small className="text-error">
                                        {ApiError.email}
                                    </small>
                                )}
                            </div>
                            <div className="form-group mb-5 pb-5 border-b border-base-200">
                                <label
                                    htmlFor="password"
                                    className="font-medium"
                                >
                                    {t('Κωδικός Πρόσβασης')}
                                </label>
                                <input
                                    {...register('password', {
                                        required: 'This field is required.',
                                    })}
                                    type="password"
                                    placeholder={t('Κωδικός Πρόσβασης')}
                                    className="w-full input input-bordered input-primary"
                                    id="password"
                                    name="password"
                                />
                                {errors.password && (
                                    <small className="text-error">
                                        {errors.password.message}
                                    </small>
                                )}
                                {ApiError?.password && (
                                    <small className="text-error">
                                        {ApiError.password}
                                    </small>
                                )}
                            </div>

                            {ApiError?.non_field_errors &&
                                ApiError.non_field_errors.map((err) => {
                                    return (
                                        <small className="text-error font-medium ">
                                            {err}
                                        </small>
                                    )
                                })}

                            <SubmitButtonComponent />

                            <div className="divider lg:divider-horizontal"></div>
                            <div className="flex flex-col gap-2 w-full mt-4 text-sm ">
                                <Link
                                    to="/signup"
                                    className="link text-blue-900 font-medium ml-auto"
                                >
                                    {t('Δεν έχω λογαριασμό')}
                                </Link>
                                <Link
                                    to="/reset-password"
                                    className="link text-blue-900 font-medium ml-auto"
                                >
                                    {t('Ξέχασα τον κωδικό μου')}
                                </Link>
                            </div>
                        </form>
                    </div>
                    <div className="m-auto">
                        <SignInWithGoogleButton />
                    </div>
                </div>

                <StateMessages />
            </div>
        </RouteBase>
    )
}

export default Login
