import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { MapContext } from '@/context/MapContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faMap } from '@fortawesome/free-solid-svg-icons'

function MobileNavItem({ active, children, ...rest }) {
    return (
        <div
            className={`flex flex-row items-center justify-center gap-2 cursor-pointer rounded-full py-2 px-4 transition-all duration-500  ${
                active ? 'bg-[#0f2254] text-white' : 'text-gray-300'
            }
           
            `}
            {...rest}
        >
            {children}
        </div>
    )
}

function SelectView() {
    const { t } = useTranslation()

    const { showMap, setShowMap } = useContext(MapContext)

    return (
        <div className="flex justify-center rounded-full p-1 bg-primary">
            <MobileNavItem active={showMap} onClick={() => setShowMap(true)}>
                <FontAwesomeIcon icon={faMap} className="text-xs" />
                {t('Χάρτης')}
            </MobileNavItem>
            <MobileNavItem active={!showMap} onClick={() => setShowMap(false)}>
                <FontAwesomeIcon icon={faList} className="text-xs" />
                {t('Λίστα')}
            </MobileNavItem>
        </div>
    )
}

export { SelectView }
