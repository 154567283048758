import React from 'react'
import { useTranslation } from 'react-i18next'
import { Connector } from '@/types/CharginStation'

import { TariffComponent } from '@/components/tariffs/Tariff'
import { ChargePolicyComponent } from '@/components/tariffs/ChargePolicy'

interface Props {
    index?: number
    connector: Connector
}
const ConnectorCostComponent: React.FC<Props> = ({ connector }) => {
    const { t } = useTranslation()
    const DetailsComponent = () => {
        if (!connector.charging_cost.details) return null

        if (connector.charging_cost.type === 'ocpi') {
            return connector.charging_cost.details.map((tariff, idx) => (
                <TariffComponent tariff={tariff} index={idx} />
            ))
        }

        if (connector.charging_cost.type === 'custom') {
            return (
                <ChargePolicyComponent
                    charge_policy={connector.charging_cost.details}
                />
            )
        }
    }
    const CostIndicator = () => {
        if (!connector.charging_cost || !connector.charging_cost?.mean_cost)
            return null
        return (
            <div className="text-secondary font-bold ml-auto text-left my-1">
                <span className="font-normal ">{t('Μέση χρέωση')}</span>
                {connector?.charging_cost?.mean_cost
                    ? connector?.charging_cost?.mean_cost
                    : ' - '}{' '}
                €/kWh
            </div>
        )
    }

    if (!connector.charging_cost) {
        return null
    }

    if (
        !connector.charging_cost.details ||
        connector.charging_cost.details.length === 0
    ) {
        return (
            <div className="mt-5">
                <CostIndicator />
            </div>
        )
    }

    return (
        <>
            <div className="collapse collapse-sm collapse-arrow bg-[#dae9ef] hover:shadow transition-all rounded-md ">
                <input type="checkbox" />
                <div className="collapse-title h-3">
                    <CostIndicator />
                </div>
                <div className="collapse-content">
                    <DetailsComponent />
                </div>
            </div>
        </>
    )
}

export { ConnectorCostComponent }
