import React, { useContext, useEffect } from 'react'
import { DataContext } from '@/context/DataContext'
import { AuthContext } from '@/context/AuthContext'
import { MapContext } from '@/context/MapContext'

import { ParameterWalkthrough } from '@/components/dashboard/ParameterWalkthrough'
import { PriceDashboardLayout } from '@/components/dashboard/DashboardLayout'

// Memoized HOC to pass the map context to the wrapped component
const withParentContext = (WrappedComponent) => {
    const MemoizedComponent = React.memo(WrappedComponent)

    return function WithMapContext(props) {
        // Dashboard Parent
        const { initialPageLoad } = useContext(DataContext)
        const { user } = useContext(AuthContext)
        const { startTracking } = useContext(MapContext)

        // Dashboard Layout
        const { data, selectedLocation, setSelectedLocation, dataLoading } =
            useContext(DataContext)

        const { userLocation } = useContext(MapContext)

        return (
            <MemoizedComponent
                {...props}
                initialPageLoad={initialPageLoad}
                user={user}
                startTracking={startTracking}
                data={data}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                dataLoading={dataLoading}
                userLocation={userLocation}
            />
        )
    }
}

const PriceDashboard = withParentContext(function PriceDashboard({
    initialPageLoad,
    user,
    startTracking,
    data,
    selectedLocation,
    setSelectedLocation,
    dataLoading,
    userLocation,
}) {
    useEffect(() => {
        if (!initialPageLoad) return
        startTracking()
    }, [])

    // First time, show the parameter walkthrough
    if (user?.isAuthenticated === false && initialPageLoad)
        return <ParameterWalkthrough />

    return (
        <PriceDashboardLayout
            data={data}
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            dataLoading={dataLoading}
            userLocation={userLocation}
        />
    )
})

export default PriceDashboard
