import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { DataContext } from '@/context/DataContext'
import { formatEditedBefore, formatDateTime } from '@/lib/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync } from '@fortawesome/free-solid-svg-icons'

function LastUpdateIndicator() {
    const { t } = useTranslation()
    const { lastUpdated } = useContext(DataContext)

    if (!lastUpdated) return null

    const updatedLabel = formatEditedBefore(lastUpdated)

    return (
        <div
            className="flex flex-row items-center gap-1 mx-4 text-gray-400 hover:text-blue-200 transition-all font-medium tooltip tooltip-right"
            data-tip={`${t('Επικοινωνία με το Μ.Υ.Φ.Α.Η.')} ${formatDateTime(
                lastUpdated
            )} `}
        >
            <FontAwesomeIcon icon={faSync} className="text-xs  font-bold" />
            <div className="text-xs">{updatedLabel}</div>
        </div>
    )
}

export { LastUpdateIndicator }
