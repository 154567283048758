import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'react-daisyui'
import { Link } from 'react-router-dom'
import { VersionBanner } from '@/components/VersionBanner'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faChevronCircleRight,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons'

import { RaaeyLogo } from '@/components/RaaeyLogo'
import { GetLogo } from '@/components/GetLogo'

// import CharginCostBanner from '@/assets/img/favicon.webp'

function Hero() {
    const { t } = useTranslation()

    return (
        <section className="container lg:max-w-7xl w-full py-10">
            <div className=" px-3 min-h-[calc(100vh-64px)] md:min-h-max flex justify-center">
                <div className="flex flex-col justify-center md:grid grid-cols-3 gap-14 w-full">
                    <div className="h-max col-span-2 text-center md:text-left">
                        <h1
                            className=" text-6xl md:text-7xl font-bold mb-4"
                            style={{
                                letterSpacing: '-5px',
                            }}
                        >
                            Charging Cost
                            <span className="text-secondary"> .gr</span>
                            <VersionBanner />
                        </h1>
                        <h2 className="text-xl mb-8">{t('home.subtitle')}</h2>
                        <Link
                            to="dashboard"
                            className="group bg-secondary text-white px-6 py-4 rounded-md transition-all hover:bg-secondary-600 font-bold text-xl hover:bg-primary focus:ring"
                        >
                            &nbsp; {t('home.cta')}
                            <FontAwesomeIcon
                                icon={faChevronRight}
                                className="ml-2 transition-all w-0 group-hover:w-5 "
                            />
                        </Link>
                    </div>

                    <div className="flex flex-row ">
                        <RaaeyLogo className="w-64 md:w-76 m-auto" />
                        {/* <img
                            src={CharginCostBanner}
                            alt="Placeholder"
                            className="h-48 m-auto"
                        /> */}
                    </div>
                </div>
            </div>
        </section>
    )
}

function Description() {
    const { t } = useTranslation()
    return (
        <section className="container lg:max-w-7xl w-full border-l-2 border-secondary shadow bg-secondary bg-opacity-[0.03] rounded-r-lg py-4">
            <div className="px-6 w-full">
                <h2 className="text-4xl font-bold mb-4">
                    {t('home.description_title')}
                </h2>
                <p className="text-lg">{t(`home.description_p1`)}</p>
                <p className="text-lg mb-8">{t(`home.description_p2`)}</p>

                <div className="w-full flex gap-3 items-baseline">
                    <Link
                        to="disclaimer"
                        className="link font-bold text-xs text-blue-900 ml-auto"
                    >
                        {t('disclaimer.title')}
                    </Link>

                    <Link to="about" className="link font-bold  text-blue-900">
                        {t('Περισσότερα')}

                        <FontAwesomeIcon
                            icon={faChevronCircleRight}
                            className="ml-2"
                        />
                    </Link>
                </div>
            </div>
        </section>
    )
}

function Actions() {
    const { t } = useTranslation()
    return (
        <section className=" py-12">
            <div className="container mx-auto px-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div>
                        <h3 className="text-2xl font-bold mb-4">
                            {t('Εγγραφή')}
                        </h3>
                        <p className="mb-8">
                            {t(
                                'Πραγματοποιώντας εγγραφή, μπορείτε να επιλέξετε τον τύπο του ηλεκτρικού σας οχήματος, και να λαμβάνετε εξατομικευμένα αποτελέσματα'
                            )}
                        </p>

                        <Link
                            to="signup"
                            className="btn btn-outline btn-secondary"
                            href=""
                        >
                            {t('Εγγραφή')}
                        </Link>
                    </div>
                    <div>
                        <h3 className="text-2xl font-bold mb-4">Android/iOS</h3>
                        <p className="mb-8">
                            {t(
                                'Κάντε λήψη της εφαρμογής για Android/iOS, για να συγκρίνετε τις τιμές των διαθέσιμων σημείων φόρτισης εύκολα και γρήγορα'
                            )}
                        </p>
                        <Button color="secondary" size="md">
                            {t('Λήψη')}
                        </Button>
                    </div>
                </div>
            </div>
        </section>
    )
}

function GetmapCredits() {
    return (
        <section className="container lg:max-w-7xl w-full">
            <div className="flex justify-end w-full">
                <GetLogo isHomepage />
            </div>
        </section>
    )
}

function HomePage() {
    const { t } = useTranslation()
    return (
        <div className="min-h-screen pb-[84px] md:pt-[64px] md:pb-10 flex flex-col justify-center mx-4 overflow-x-hidden">
            <main className="h-full flex flex-col justify-center gap-10 ">
                <Hero />
                <Description />

                {/* <Actions /> */}
                <GetmapCredits />
            </main>
        </div>
    )
}

export default HomePage
