import React from 'react'
import Logo from '@/assets/img/get_logo.png'

function GetLogo({ className, isHomepage, ...rest }) {
    if (isHomepage)
        return (
            <a
                href="https://getmap.eu/"
                target="_blank"
                className="pr-1 pl-3 flex items-center gap-1 font-bold  transition-all cursor-pointer "
            >
                <span className="">Developed by </span>{' '}
                <img src={Logo} alt="Getmap Logo" className="h-10 p-1" />
            </a>
        )

    return (
        <a
            href="https://getmap.eu/"
            target="_blank"
            className="group fixed bottom-[105px] md:bottom-10 right-0 z-[-1] pr-1 pl-3 flex items-center gap-1 text-white font-bold rounded-l-full transition-all hover:bg-blue-300 hover:bg-opacity-70 hover:shadow-lg  cursor-pointer"
        >
            <span className="transition-all translate-x-[200px] group-hover:translate-x-0">
                Powered by
            </span>{' '}
            <img src={Logo} alt="Getmap Logo" className="h-10 p-1" />
        </a>
    )
}

export { GetLogo }
