import React, { useState, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { debounce } from 'lodash'

import { useTranslation } from 'react-i18next'

function SearchNameInput({ onChange, value }) {
    const { t } = useTranslation()
    const [searchText, setSearchText] = useState(value)
    const [loading, setLoading] = useState(false)

    // Set loding to false after each call of the onChange function
    const onChangeAndStopLoading = (searchText) => {
        onChange(searchText)
        setLoading(false)
    }

    // Debounce the onChange function
    const debouncedOnChange = useRef(debounce(onChangeAndStopLoading, 1000)) // Adjust the delay as needed (e.g., 300 milliseconds)

    const handleSearchTextChange = (e) => {
        setLoading(true)
        setSearchText(e.target.value)
        debouncedOnChange.current(e.target.value)
        if (e.key === 'Enter') {
            onChangeAndStopLoading(e.target.value)
        }
    }

    const clearSearchText = () => {
        setSearchText('')
        onChangeAndStopLoading('')
    }

    return (
        <div className="flex flex-row items-center ml-3">
            <input
                type="text"
                placeholder={t('Αναζήτηση (όνομα)')}
                className="input input-bordered input-xs max-w-xs"
                value={searchText}
                onChange={handleSearchTextChange}
                onKeyDown={handleSearchTextChange}
            />
            {searchText !== '' && !loading ? (
                <button
                    className="btn btn-xs btn-circle btn-ghost ml-[-30px] tooltip tooltip-right"
                    data-tip={t('Καθαρισμός αναζήτησης')}
                    onClick={clearSearchText}
                >
                    <FontAwesomeIcon icon={faXmark} />
                </button>
            ) : null}
            {loading ? (
                <FontAwesomeIcon
                    icon={faSpinner}
                    className=" ml-[-30px] text-gray-400"
                    spin={loading}
                />
            ) : null}
        </div>
    )
}

export { SearchNameInput }
