/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConnectorFormat } from '../models/ConnectorFormat';
import type { ConnectorPowerType } from '../models/ConnectorPowerType';
import type { ConnectorType } from '../models/ConnectorType';
import type { EVSECapability } from '../models/EVSECapability';
import type { EVSEParkingRestriction } from '../models/EVSEParkingRestriction';
import type { EVSEStatus } from '../models/EVSEStatus';
import type { TariffType } from '../models/TariffType';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OcpiService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ConnectorFormat 
     * @throws ApiError
     */
    public ocpiConnectorFormatsList(): CancelablePromise<Array<ConnectorFormat>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ocpi/connector-formats/',
        });
    }

    /**
     * @param data 
     * @returns ConnectorFormat 
     * @throws ApiError
     */
    public ocpiConnectorFormatsCreate(
data: ConnectorFormat,
): CancelablePromise<ConnectorFormat> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/ocpi/connector-formats/',
            body: data,
        });
    }

    /**
     * @param id A unique integer value identifying this connector format.
     * @returns ConnectorFormat 
     * @throws ApiError
     */
    public ocpiConnectorFormatsRead(
id: number,
): CancelablePromise<ConnectorFormat> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ocpi/connector-formats/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this connector format.
     * @param data 
     * @returns ConnectorFormat 
     * @throws ApiError
     */
    public ocpiConnectorFormatsUpdate(
id: number,
data: ConnectorFormat,
): CancelablePromise<ConnectorFormat> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/ocpi/connector-formats/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @param id A unique integer value identifying this connector format.
     * @param data 
     * @returns ConnectorFormat 
     * @throws ApiError
     */
    public ocpiConnectorFormatsPartialUpdate(
id: number,
data: ConnectorFormat,
): CancelablePromise<ConnectorFormat> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/ocpi/connector-formats/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @param id A unique integer value identifying this connector format.
     * @returns void 
     * @throws ApiError
     */
    public ocpiConnectorFormatsDelete(
id: number,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/ocpi/connector-formats/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ConnectorPowerType 
     * @throws ApiError
     */
    public ocpiConnectorPowerTypesList(): CancelablePromise<Array<ConnectorPowerType>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ocpi/connector-power-types/',
        });
    }

    /**
     * @param data 
     * @returns ConnectorPowerType 
     * @throws ApiError
     */
    public ocpiConnectorPowerTypesCreate(
data: ConnectorPowerType,
): CancelablePromise<ConnectorPowerType> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/ocpi/connector-power-types/',
            body: data,
        });
    }

    /**
     * @param id A unique integer value identifying this connector power type.
     * @returns ConnectorPowerType 
     * @throws ApiError
     */
    public ocpiConnectorPowerTypesRead(
id: number,
): CancelablePromise<ConnectorPowerType> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ocpi/connector-power-types/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this connector power type.
     * @param data 
     * @returns ConnectorPowerType 
     * @throws ApiError
     */
    public ocpiConnectorPowerTypesUpdate(
id: number,
data: ConnectorPowerType,
): CancelablePromise<ConnectorPowerType> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/ocpi/connector-power-types/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @param id A unique integer value identifying this connector power type.
     * @param data 
     * @returns ConnectorPowerType 
     * @throws ApiError
     */
    public ocpiConnectorPowerTypesPartialUpdate(
id: number,
data: ConnectorPowerType,
): CancelablePromise<ConnectorPowerType> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/ocpi/connector-power-types/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @param id A unique integer value identifying this connector power type.
     * @returns void 
     * @throws ApiError
     */
    public ocpiConnectorPowerTypesDelete(
id: number,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/ocpi/connector-power-types/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ConnectorType 
     * @throws ApiError
     */
    public ocpiConnectorTypesList(): CancelablePromise<Array<ConnectorType>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ocpi/connector-types/',
        });
    }

    /**
     * @param data 
     * @returns ConnectorType 
     * @throws ApiError
     */
    public ocpiConnectorTypesCreate(
data: ConnectorType,
): CancelablePromise<ConnectorType> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/ocpi/connector-types/',
            body: data,
        });
    }

    /**
     * @param id A unique integer value identifying this connector type.
     * @returns ConnectorType 
     * @throws ApiError
     */
    public ocpiConnectorTypesRead(
id: number,
): CancelablePromise<ConnectorType> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ocpi/connector-types/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this connector type.
     * @param data 
     * @returns ConnectorType 
     * @throws ApiError
     */
    public ocpiConnectorTypesUpdate(
id: number,
data: ConnectorType,
): CancelablePromise<ConnectorType> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/ocpi/connector-types/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @param id A unique integer value identifying this connector type.
     * @param data 
     * @returns ConnectorType 
     * @throws ApiError
     */
    public ocpiConnectorTypesPartialUpdate(
id: number,
data: ConnectorType,
): CancelablePromise<ConnectorType> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/ocpi/connector-types/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @param id A unique integer value identifying this connector type.
     * @returns void 
     * @throws ApiError
     */
    public ocpiConnectorTypesDelete(
id: number,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/ocpi/connector-types/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param tariffTypes Include Tariff Type
     * @param connectorStandards Include Connector Standard
     * @param connectorFormats Include Connector Format
     * @param connectorPowerTypes Include Connector Power Type
     * @param evseStatuses Include EVSE Status
     * @param evseParkingRestrictions Include EVSE Parking Restriction
     * @param evseCapabilities Include EVSE Capability
     * @returns any OK
     * @throws ApiError
     */
    public ocpiDomainValuesList(
tariffTypes?: boolean,
connectorStandards?: boolean,
connectorFormats?: boolean,
connectorPowerTypes?: boolean,
evseStatuses?: boolean,
evseParkingRestrictions?: boolean,
evseCapabilities?: boolean,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ocpi/domain-values',
            query: {
                'tariff_types': tariffTypes,
                'connector_standards': connectorStandards,
                'connector_formats': connectorFormats,
                'connector_power_types': connectorPowerTypes,
                'evse_statuses': evseStatuses,
                'evse_parking_restrictions': evseParkingRestrictions,
                'evse_capabilities': evseCapabilities,
            },
        });
    }

    /**
     * @returns EVSECapability 
     * @throws ApiError
     */
    public ocpiEvseCapabilitiesList(): CancelablePromise<Array<EVSECapability>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ocpi/evse-capabilities/',
        });
    }

    /**
     * @param data 
     * @returns EVSECapability 
     * @throws ApiError
     */
    public ocpiEvseCapabilitiesCreate(
data: EVSECapability,
): CancelablePromise<EVSECapability> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/ocpi/evse-capabilities/',
            body: data,
        });
    }

    /**
     * @param id A unique integer value identifying this evse capability.
     * @returns EVSECapability 
     * @throws ApiError
     */
    public ocpiEvseCapabilitiesRead(
id: number,
): CancelablePromise<EVSECapability> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ocpi/evse-capabilities/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this evse capability.
     * @param data 
     * @returns EVSECapability 
     * @throws ApiError
     */
    public ocpiEvseCapabilitiesUpdate(
id: number,
data: EVSECapability,
): CancelablePromise<EVSECapability> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/ocpi/evse-capabilities/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @param id A unique integer value identifying this evse capability.
     * @param data 
     * @returns EVSECapability 
     * @throws ApiError
     */
    public ocpiEvseCapabilitiesPartialUpdate(
id: number,
data: EVSECapability,
): CancelablePromise<EVSECapability> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/ocpi/evse-capabilities/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @param id A unique integer value identifying this evse capability.
     * @returns void 
     * @throws ApiError
     */
    public ocpiEvseCapabilitiesDelete(
id: number,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/ocpi/evse-capabilities/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns EVSEParkingRestriction 
     * @throws ApiError
     */
    public ocpiEvseParkingRestrictionsList(): CancelablePromise<Array<EVSEParkingRestriction>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ocpi/evse-parking-restrictions/',
        });
    }

    /**
     * @param data 
     * @returns EVSEParkingRestriction 
     * @throws ApiError
     */
    public ocpiEvseParkingRestrictionsCreate(
data: EVSEParkingRestriction,
): CancelablePromise<EVSEParkingRestriction> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/ocpi/evse-parking-restrictions/',
            body: data,
        });
    }

    /**
     * @param id A unique integer value identifying this evse parking restriction.
     * @returns EVSEParkingRestriction 
     * @throws ApiError
     */
    public ocpiEvseParkingRestrictionsRead(
id: number,
): CancelablePromise<EVSEParkingRestriction> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ocpi/evse-parking-restrictions/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this evse parking restriction.
     * @param data 
     * @returns EVSEParkingRestriction 
     * @throws ApiError
     */
    public ocpiEvseParkingRestrictionsUpdate(
id: number,
data: EVSEParkingRestriction,
): CancelablePromise<EVSEParkingRestriction> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/ocpi/evse-parking-restrictions/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @param id A unique integer value identifying this evse parking restriction.
     * @param data 
     * @returns EVSEParkingRestriction 
     * @throws ApiError
     */
    public ocpiEvseParkingRestrictionsPartialUpdate(
id: number,
data: EVSEParkingRestriction,
): CancelablePromise<EVSEParkingRestriction> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/ocpi/evse-parking-restrictions/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @param id A unique integer value identifying this evse parking restriction.
     * @returns void 
     * @throws ApiError
     */
    public ocpiEvseParkingRestrictionsDelete(
id: number,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/ocpi/evse-parking-restrictions/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns EVSEStatus 
     * @throws ApiError
     */
    public ocpiEvseStatusList(): CancelablePromise<Array<EVSEStatus>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ocpi/evse-status/',
        });
    }

    /**
     * @param data 
     * @returns EVSEStatus 
     * @throws ApiError
     */
    public ocpiEvseStatusCreate(
data: EVSEStatus,
): CancelablePromise<EVSEStatus> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/ocpi/evse-status/',
            body: data,
        });
    }

    /**
     * @param id A unique integer value identifying this evse status.
     * @returns EVSEStatus 
     * @throws ApiError
     */
    public ocpiEvseStatusRead(
id: number,
): CancelablePromise<EVSEStatus> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ocpi/evse-status/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this evse status.
     * @param data 
     * @returns EVSEStatus 
     * @throws ApiError
     */
    public ocpiEvseStatusUpdate(
id: number,
data: EVSEStatus,
): CancelablePromise<EVSEStatus> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/ocpi/evse-status/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @param id A unique integer value identifying this evse status.
     * @param data 
     * @returns EVSEStatus 
     * @throws ApiError
     */
    public ocpiEvseStatusPartialUpdate(
id: number,
data: EVSEStatus,
): CancelablePromise<EVSEStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/ocpi/evse-status/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @param id A unique integer value identifying this evse status.
     * @returns void 
     * @throws ApiError
     */
    public ocpiEvseStatusDelete(
id: number,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/ocpi/evse-status/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Returns the ingestion log from the database, given the id of the ingestion.
     * @param id Ingestion ID
     * @returns any OK
     * @throws ApiError
     */
    public ocpiIngestionLogsList(
id: string,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ocpi/ingestion-logs',
            query: {
                'id': id,
            },
        });
    }

    /**
     * No args, returns the datetime of the last ingestion log
     * @returns any OK
     * @throws ApiError
     */
    public ocpiLastUpdateList(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ocpi/last-update',
        });
    }

    /**
     * @returns TariffType 
     * @throws ApiError
     */
    public ocpiTariffTypesList(): CancelablePromise<Array<TariffType>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ocpi/tariff-types/',
        });
    }

    /**
     * @param data 
     * @returns TariffType 
     * @throws ApiError
     */
    public ocpiTariffTypesCreate(
data: TariffType,
): CancelablePromise<TariffType> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/ocpi/tariff-types/',
            body: data,
        });
    }

    /**
     * @param id A unique integer value identifying this tariff type.
     * @returns TariffType 
     * @throws ApiError
     */
    public ocpiTariffTypesRead(
id: number,
): CancelablePromise<TariffType> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ocpi/tariff-types/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this tariff type.
     * @param data 
     * @returns TariffType 
     * @throws ApiError
     */
    public ocpiTariffTypesUpdate(
id: number,
data: TariffType,
): CancelablePromise<TariffType> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/ocpi/tariff-types/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @param id A unique integer value identifying this tariff type.
     * @param data 
     * @returns TariffType 
     * @throws ApiError
     */
    public ocpiTariffTypesPartialUpdate(
id: number,
data: TariffType,
): CancelablePromise<TariffType> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/ocpi/tariff-types/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @param id A unique integer value identifying this tariff type.
     * @returns void 
     * @throws ApiError
     */
    public ocpiTariffTypesDelete(
id: number,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/ocpi/tariff-types/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
