/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Company } from '../models/Company';
import type { Location } from '../models/Location';
import type { LocationChargingCost } from '../models/LocationChargingCost';
import type { LocationConcise } from '../models/LocationConcise';
import type { LocationPoints } from '../models/LocationPoints';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ApiService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Επιστρέφει μόνο τα Locations Που ανήκουν στην εταιρεία του χρήστη
     * @returns LocationConcise 
     * @throws ApiError
     */
    public apiCompanyLocationsList(): CancelablePromise<Array<LocationConcise>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/company-locations/',
        });
    }

    /**
     * Επιστρέφει μόνο τα Locations Που ανήκουν στην εταιρεία του χρήστη
     * @param id 
     * @returns LocationConcise 
     * @throws ApiError
     */
    public apiCompanyLocationsRead(
id: string,
): CancelablePromise<LocationConcise> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/company-locations/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Company 
     * @throws ApiError
     */
    public apiListCompaniesList(): CancelablePromise<Array<Company>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/list-companies/',
        });
    }

    /**
     * Retrieves a location by id, including its charging cost
     * @returns LocationChargingCost 
     * @throws ApiError
     */
    public apiLocationChargingCostList(): CancelablePromise<Array<LocationChargingCost>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/location-charging-cost/',
        });
    }

    /**
     * Get locations, with charging cost information
     * @param id A unique integer value identifying this Σταθμός Φόρτισης.
     * @param askedKwh Charge amount in kWh
     * @param askedEuro Charge amount in total cost (euros)
     * @param askedDuration Charge session duration
     * @returns LocationChargingCost 
     * @throws ApiError
     */
    public apiLocationChargingCostRead(
id: number,
askedKwh?: number,
askedEuro?: number,
askedDuration?: number,
): CancelablePromise<Array<LocationChargingCost>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/location-charging-cost/{id}/',
            path: {
                'id': id,
            },
            query: {
                'asked_kwh': askedKwh,
                'asked_euro': askedEuro,
                'asked_duration': askedDuration,
            },
        });
    }

    /**
     * Απλή CRUD διεπαφή για τα σημεία φόρτισης
     * @returns Location 
     * @throws ApiError
     */
    public apiLocationsList(): CancelablePromise<Array<Location>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/locations/',
        });
    }

    /**
     * Απλή CRUD διεπαφή για τα σημεία φόρτισης
     * @param data 
     * @returns Location 
     * @throws ApiError
     */
    public apiLocationsCreate(
data: Location,
): CancelablePromise<Location> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/locations/',
            body: data,
        });
    }

    /**
     * Απλή CRUD διεπαφή για τα σημεία φόρτισης
     * @param id A unique integer value identifying this Σταθμός Φόρτισης.
     * @returns Location 
     * @throws ApiError
     */
    public apiLocationsRead(
id: number,
): CancelablePromise<Location> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/locations/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Απλή CRUD διεπαφή για τα σημεία φόρτισης
     * @param id A unique integer value identifying this Σταθμός Φόρτισης.
     * @param data 
     * @returns Location 
     * @throws ApiError
     */
    public apiLocationsUpdate(
id: number,
data: Location,
): CancelablePromise<Location> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/locations/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * Απλή CRUD διεπαφή για τα σημεία φόρτισης
     * @param id A unique integer value identifying this Σταθμός Φόρτισης.
     * @param data 
     * @returns Location 
     * @throws ApiError
     */
    public apiLocationsPartialUpdate(
id: number,
data: Location,
): CancelablePromise<Location> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/locations/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * Απλή CRUD διεπαφή για τα σημεία φόρτισης
     * @param id A unique integer value identifying this Σταθμός Φόρτισης.
     * @returns void 
     * @throws ApiError
     */
    public apiLocationsDelete(
id: number,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/locations/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Get locations within a specified distance or the closest X points to the given lng/lat.
     * @param lng Longitude
     * @param lat Latitude
     * @param srid Spatial reference system identifier (Default: 4326)
     * @param maxDistance Maximum distance in meters
     * @param closestX Retrieve the closest X locations (Cannot be used in combination with max_distance)
     * @param limit Limit the number of results
     * @param company Operating company id
     * @param isOpen Return only curently open locations
     * @param geojson Response format (json or geojson)
     * @param connectorType At least one available EVSE with any of the requested connector types (CHADEMO,...)
     * @param connectorPowerTypes At least one available EVSE with any of the requested power types (AC_1_PHASE,...)
     * @param connectorFormat At least one available EVSE with any of the requested connector formats (SOCKET,...)
     * @param evseCapabilities At least one available EVSE with any of the requested capabilities (RESERVABLE,...)
     * @param status At least one available EVSE with any of the requested statuses (AVAILABLE,...)
     * @param city City
     * @param address Search by address
     * @param voltage At least one available Connector with max_voltage >= requested voltage
     * @param amperage At least one available Connector with max_amperage >= requested amperage
     * @param electricPower At least one available Connector with max_electric_power >= requested electric_power
     * @param askedKwh Charge amount in kWh
     * @param askedEuro Charge amount in total cost (euros)
     * @param askedDuration Charge session duration
     * @param q Full text search query
     * @returns Location 
     * @throws ApiError
     */
    public apiQueryLocationsList(
lng: number,
lat: number,
srid?: number,
maxDistance?: number,
closestX?: number,
limit?: number,
company?: number,
isOpen?: boolean,
geojson?: boolean,
connectorType?: Array<string>,
connectorPowerTypes?: Array<string>,
connectorFormat?: Array<string>,
evseCapabilities?: Array<string>,
status?: Array<string>,
city?: string,
address?: string,
voltage?: number,
amperage?: number,
electricPower?: number,
askedKwh?: number,
askedEuro?: number,
askedDuration?: number,
q?: string,
): CancelablePromise<Array<Location>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/query-locations/',
            query: {
                'lng': lng,
                'lat': lat,
                'srid': srid,
                'max_distance': maxDistance,
                'closest_x': closestX,
                'limit': limit,
                'company': company,
                'is_open': isOpen,
                'geojson': geojson,
                'connector_type': connectorType,
                'connector_power_types': connectorPowerTypes,
                'connector_format': connectorFormat,
                'evse_capabilities': evseCapabilities,
                'status': status,
                'city': city,
                'address': address,
                'voltage': voltage,
                'amperage': amperage,
                'electric_power': electricPower,
                'asked_kwh': askedKwh,
                'asked_euro': askedEuro,
                'asked_duration': askedDuration,
                'q': q,
            },
        });
    }

    /**
     * Get locations within a specified distance or the closest X points to the given lng/lat.
     * @param lng Longitude
     * @param lat Latitude
     * @param srid Spatial reference system identifier (Default: 4326)
     * @param maxDistance Maximum distance in meters
     * @param closestX Retrieve the closest X locations (Cannot be used in combination with max_distance)
     * @param limit Limit the number of results
     * @param company Operating company id
     * @param isOpen Return only curently open locations
     * @param geojson Response format (json or geojson)
     * @param connectorType At least one available EVSE with any of the requested connector types (CHADEMO,...)
     * @param connectorPowerTypes At least one available EVSE with any of the requested power types (AC_1_PHASE,...)
     * @param connectorFormat At least one available EVSE with any of the requested connector formats (SOCKET,...)
     * @param evseCapabilities At least one available EVSE with any of the requested capabilities (RESERVABLE,...)
     * @param status At least one available EVSE with any of the requested statuses (AVAILABLE,...)
     * @param city City
     * @param address Search by address
     * @param voltage At least one available Connector with max_voltage >= requested voltage
     * @param amperage At least one available Connector with max_amperage >= requested amperage
     * @param electricPower At least one available Connector with max_electric_power >= requested electric_power
     * @param askedKwh Charge amount in kWh
     * @param askedEuro Charge amount in total cost (euros)
     * @param askedDuration Charge session duration
     * @param q Full text search query
     * @returns LocationPoints 
     * @throws ApiError
     */
    public apiQueryPointsList(
lng: number,
lat: number,
srid?: number,
maxDistance?: number,
closestX?: number,
limit?: number,
company?: number,
isOpen?: boolean,
geojson?: boolean,
connectorType?: Array<string>,
connectorPowerTypes?: Array<string>,
connectorFormat?: Array<string>,
evseCapabilities?: Array<string>,
status?: Array<string>,
city?: string,
address?: string,
voltage?: number,
amperage?: number,
electricPower?: number,
askedKwh?: number,
askedEuro?: number,
askedDuration?: number,
q?: string,
): CancelablePromise<Array<LocationPoints>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/query-points/',
            query: {
                'lng': lng,
                'lat': lat,
                'srid': srid,
                'max_distance': maxDistance,
                'closest_x': closestX,
                'limit': limit,
                'company': company,
                'is_open': isOpen,
                'geojson': geojson,
                'connector_type': connectorType,
                'connector_power_types': connectorPowerTypes,
                'connector_format': connectorFormat,
                'evse_capabilities': evseCapabilities,
                'status': status,
                'city': city,
                'address': address,
                'voltage': voltage,
                'amperage': amperage,
                'electric_power': electricPower,
                'asked_kwh': askedKwh,
                'asked_euro': askedEuro,
                'asked_duration': askedDuration,
                'q': q,
            },
        });
    }

}
