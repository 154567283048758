import { Navbar } from 'react-daisyui'
import { useLocation } from 'react-router-dom'
import React, { useState } from 'react' // Import React and other necessary dependencies
import NavLinks from './NavLinks'
import NavAuthComponent from './NavAuthComponent'
import { LastUpdateIndicator } from '@/components/LastUpdated'
import LanguageControl from '@/components/LanguageControl'
import { isMobile } from '@/lib/utils'

import { SelectView } from '@/components/mobile/SelectView'
import { MobileMenu } from '@/components/mobile/MobileMenu'
import { OpenFiltersMenuBtn } from '@/components/mobile/OpenFiltersMenuBtn'

function NavMobile({ children }) {
    const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)
    const location = useLocation()

    return (
        <div className="z-50 flex md:hidden w-full items-center justify-center font-sans fixed bottom-[0] bg-[#0f2254] text-white border-t border-black">
            <Navbar className="px-2 gap-3 py-0">
                <Navbar.Start>
                    <OpenFiltersMenuBtn setOpen={setMobileFiltersOpen} />
                </Navbar.Start>

                <Navbar.Center className="flex flex-row items-center">
                    <NavLinks></NavLinks>

                    {location.pathname === '/dashboard' ? <SelectView /> : null}

                    <MobileMenu
                        isOpen={mobileFiltersOpen}
                        setOpen={setMobileFiltersOpen}
                        handleContinue={() => {}}
                        locationLoading={false}
                    />
                </Navbar.Center>

                <Navbar.End>
                    {/* <LanguageControl
                    className="dropdown-top"
                    small
                    noIcon
                /> */}
                    <NavAuthComponent expanded={false} />
                </Navbar.End>
            </Navbar>
        </div>
    )
}

function NavDesktop({ children }) {
    return (
        <div className="z-40 hidden md:flex w-full bg-[#0f2254] transition-all duration-700 bg-opacity-0 hover:bg-opacity-100 hover:text-white hover:shadow-lg items-center justify-center gap-2 font-sans fixed top-[0]">
            <Navbar>
                <Navbar.Start>
                    {children}
                    <LastUpdateIndicator />
                </Navbar.Start>

                <Navbar.Center className="hidden lg:flex"></Navbar.Center>

                <Navbar.End className="hidden md:flex">
                    <NavLinks></NavLinks>
                    <LanguageControl />
                </Navbar.End>
            </Navbar>
        </div>
    )
}

function NavbarComponent({ children }) {
    const isMobileDevice = isMobile()

    return isMobileDevice ? (
        <NavMobile>{children}</NavMobile>
    ) : (
        <NavDesktop>{children}</NavDesktop>
    )
}

export default NavbarComponent
