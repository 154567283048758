/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Vehicle } from '../models/Vehicle';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class VehiclesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param defaultVehicle 
     * @returns any Default vehicle set successfully
     * @throws ApiError
     */
    public vehiclesSetDefaultVehicleUpdate(
defaultVehicle: number,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/vehicles/set-default-vehicle/',
            query: {
                'default_vehicle': defaultVehicle,
            },
            errors: {
                400: `Bad request`,
            },
        });
    }

    /**
     * @returns Vehicle 
     * @throws ApiError
     */
    public vehiclesVehiclesList(): CancelablePromise<Array<Vehicle>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/vehicles/vehicles/',
        });
    }

    /**
     * @param data 
     * @returns Vehicle 
     * @throws ApiError
     */
    public vehiclesVehiclesCreate(
data: Vehicle,
): CancelablePromise<Vehicle> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/vehicles/vehicles/',
            body: data,
        });
    }

    /**
     * @param id A unique integer value identifying this Ηλεκτρικό Όχημα.
     * @returns Vehicle 
     * @throws ApiError
     */
    public vehiclesVehiclesRead(
id: number,
): CancelablePromise<Vehicle> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/vehicles/vehicles/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this Ηλεκτρικό Όχημα.
     * @param data 
     * @returns Vehicle 
     * @throws ApiError
     */
    public vehiclesVehiclesUpdate(
id: number,
data: Vehicle,
): CancelablePromise<Vehicle> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/vehicles/vehicles/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @param id A unique integer value identifying this Ηλεκτρικό Όχημα.
     * @param data 
     * @returns Vehicle 
     * @throws ApiError
     */
    public vehiclesVehiclesPartialUpdate(
id: number,
data: Vehicle,
): CancelablePromise<Vehicle> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/vehicles/vehicles/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @param id A unique integer value identifying this Ηλεκτρικό Όχημα.
     * @returns void 
     * @throws ApiError
     */
    public vehiclesVehiclesDelete(
id: number,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/vehicles/vehicles/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
