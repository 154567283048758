const getUniqueOperators = (data) =>
    data?.features
        ?.reduce((uniqueOperators, location) => {
            const operator = location.properties.operator
            if (operator && !uniqueOperators.includes(operator)) {
                uniqueOperators.push(operator)
            }
            return uniqueOperators
        }, [])
        .sort(
            (a, b) => a.localeCompare(b || '') // sort alphabetically
        ) || []

const filterData = (data, selectedOperator, searchText, showNullCost) => {
    if (!data) return null
    return (
        data?.features?.filter(
            (location) =>
                location.properties.operator
                    .toLowerCase()
                    .includes(selectedOperator.toLowerCase()) &&
                (showNullCost
                    ? true
                    : location.properties.charging_cost !== null) &&
                (location.properties.name
                    .toLowerCase()
                    .includes(searchText.toLowerCase()) ||
                    location.properties.operator
                        .toLowerCase()
                        .includes(searchText.toLowerCase()))
        ) || []
    )
}

const getSortingValue = (location, sortingOption) => {
    switch (sortingOption) {
        case 'priceAscending':
        case 'priceDescending':
            return location.properties.charging_cost || Infinity
        case 'distanceAscending':
        case 'distanceDescending':
            return location.properties.distanceFromUser || Infinity
        case 'operatorAscending':
        case 'operatorDescending':
            return location.properties.operator || ''
        default:
            return Infinity
    }
}

const sortData = (data, sortingOption) => {
    // console.log('sortingOption', sortingOption)
    return (
        data?.sort((a, b) => {
            const sortingValueA = getSortingValue(a, sortingOption)
            const sortingValueB = getSortingValue(b, sortingOption)

            if (
                sortingOption === 'priceAscending' ||
                sortingOption === 'distanceAscending'
            ) {
                return sortingValueA - sortingValueB
            }

            if (
                sortingOption === 'priceDescending' ||
                sortingOption === 'distanceDescending'
            ) {
                return sortingValueB - sortingValueA
            }

            if (sortingOption === 'operatorAscending') {
                return sortingValueA.localeCompare(sortingValueB)
            }

            if (sortingOption === 'operatorDescending') {
                return sortingValueB.localeCompare(sortingValueA)
            }

            return 0
        }) || []
    )
}

export { getUniqueOperators, filterData, sortData }
