import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { DataContext } from '@/context/DataContext'
import { MapContext } from '@/context/MapContext'
import { RoutingContext } from '@/context/RoutingContext'
import { CircleButton } from '@/components/buttons/CircleButton'
import { OpeningTimesComponent } from './OpeningTimes'
import {
    formatDateTime,
    formatEditedBefore,
    alertTimeElapsed,
} from '@/lib/utils'

import { LocationDetailsError } from '@/components/locations/LocationDetailsError'
import EvseList from '../evses/EvseList'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faXmark,
    faClockRotateLeft,
    faLocationCrosshairs,
    faDiamondTurnRight,
    faDownLeftAndUpRightToCenter,
    faArrowUpRightFromSquare,
    faExclamationCircle,
    faChevronLeft,
} from '@fortawesome/free-solid-svg-icons'
import GoogleLogo from '@/assets/img/google-logo.png'

import { useTraceUpdate, isMobile } from '@/lib/utils'

function mapsSelector() {
    if (
        /* if we're on iOS, open in Apple Maps */
        navigator.platform.indexOf('iPhone') != -1 ||
        navigator.platform.indexOf('iPad') != -1 ||
        navigator.platform.indexOf('iPod') != -1
    )
        return 'maps://maps.google.com/maps'
    /* else use Google */ else return 'https://maps.google.com/maps'
}

function openGmaps(userLocation, [lng, lat, h]) {
    let baseurl = mapsSelector()
    if (!baseurl) baseurl = 'https://www.google.com/maps'

    if (userLocation && userLocation.latitude && userLocation.longitude) {
        const url = `${baseurl}/dir/?api=1&origin=${userLocation.latitude},${userLocation.longitude}&destination=${lat},${lng}`
        window.open(url, '_blank')
    } else {
        const url = `${baseurl}/maps/search/?api=1&query=${lat},${lng}`
        window.open(url, '_blank')
    }
}

function useMapZoomTo() {
    const { currentMap } = useContext(MapContext)

    const handleMapZoom = (coordinates) => {
        currentMap.flyTo({
            center: coordinates,
            zoom: 15,
            essential: true,
            speed: 0.5,
        })
    }

    return handleMapZoom
}

function LocationDetails({ selectedLocation, onDashboard = false }) {
    const { setSelectedLocation, selectedLocationDetails } =
        useContext<any>(DataContext)
    const { userLocation } = useContext(MapContext)
    const { getRoute } = useContext(RoutingContext)
    const { t } = useTranslation() // Initialize the translation function

    useTraceUpdate('LocationDetails', {
        selectedLocation,
        selectedLocationDetails,
        onDashboard,
    })

    const [minimized, setMinimized] = useState(false)
    useEffect(() => {
        setMinimized(false)
    }, [selectedLocation])

    const handleMapZoom = useMapZoomTo()

    if (!selectedLocation) return null

    if (!selectedLocationDetails || selectedLocationDetails == null)
        return <LocationDetailsError />

    //if (dataLoading) return <LocationDetailsError />
    const lastUpdate = formatDateTime(selectedLocationDetails.last_updated)

    const updatedLongAgo = alertTimeElapsed(
        selectedLocationDetails.last_updated,
        {
            days: 1,
        }
    )
    const CloseButton = () => {
        return (
            <div className="flex flex-row gap-1 ml-auto items-center">
                <CircleButton
                    icon={faDownLeftAndUpRightToCenter}
                    tooltip={t('Ελαχιστοποίηση')}
                    onClick={() => setMinimized(true)}
                    className="text-xs "
                />

                <CircleButton
                    icon={faXmark}
                    tooltip={t('Κλείσιμο')}
                    onClick={() => {
                        setSelectedLocation(null)
                    }}
                />
            </div>
        )
    }

    const BackButton = () => {
        return (
            <button
                className="p-1 cursor-pointer tooltip tooltip-left ml-auto"
                data-tip={t('Όλοι οι Σταθμοί')}
                onClick={() => {
                    setSelectedLocation(null)
                }}
            >
                <FontAwesomeIcon icon={faChevronLeft} />
            </button>
        )
    }

    if (minimized)
        return (
            <div className="p-4 w-full text-xs md:rounded-lg bg-base-100 shadow-2xl border border-gray-300 ">
                <div className="flex flex-row w-full px-2 py-0 items-center gap-2">
                    <div className="font-bold">
                        {selectedLocationDetails.name}{' '}
                    </div>
                    <button
                        className="btn btn-xs btn-ghost btn-circle ml-auto"
                        onClick={() => {
                            setMinimized(false)
                        }}
                    >
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                    </button>
                </div>
            </div>
        )

    return (
        <div
            className={`relative p-4 w-full h-full text-xs flex flex-col border border-gray-300 overflow-y-hidden ${
                onDashboard
                    ? 'shadow-inner border-none'
                    : 'md:rounded-lg bg-base-100 shadow-2xl'
            }
            ${isMobile() ? 'border-none bg-none shadow-none pt-0' : ''}
            `}
        >
            {/* IMAGE */}
            {selectedLocationDetails.images?.length > 0 ? (
                <div
                    className="absolute top-0 left-0 w-full h-36 bg-fit opacity-70 bg-center bg-no-repeat md:rounded-t-lg z-0"
                    style={{
                        backgroundImage: `url(${selectedLocationDetails.images[0].url})`,
                    }}
                >
                    <div
                        className="w-full h-full bg-opacity-70 "
                        style={{
                            background: onDashboard
                                ? 'linear-gradient(180deg, rgba(100, 100, 100, 0.5) 0%, #e9ecf0 100%)'
                                : 'linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, #eff1f5 100%)',
                        }}
                    />
                </div>
            ) : null}

            <div className="flex flex-col w-ful md:pb-1 text-lg z-10">
                <div className="flex flex-row items-baseline content-baseline">
                    <h2
                        className="text-sm md:text-lg font-bold cursor-pointer"
                        onClick={() => {
                            handleMapZoom(selectedLocation.geometry.coordinates)
                        }}
                    >
                        {selectedLocationDetails.name}
                        <span className="font-medium text-secondary text-xs block">
                            {selectedLocationDetails.location_id}
                        </span>
                    </h2>
                    {!isMobile() ? (
                        onDashboard ? (
                            <BackButton />
                        ) : (
                            <CloseButton />
                        )
                    ) : null}
                </div>
                <p className="text-xs md:text-sm font-medium prose ">
                    {selectedLocationDetails.address},{' '}
                    {selectedLocationDetails.city}{' '}
                    {selectedLocationDetails.postal_code}
                </p>
            </div>

            <div className="flex flex-row items-center content-center h-min z-10">
                <div className="flex flex-col gap-1 w-full items-baseline content-baseline ">
                    {selectedLocationDetails.operator ? (
                        <a
                            className="text-xs "
                            href={selectedLocationDetails.company?.WebSite}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {t('Εκμετάλλευση')}:
                            <span className="font-bold">
                                {' '}
                                {selectedLocationDetails.operator}
                            </span>
                        </a>
                    ) : null}
                    {selectedLocationDetails.suboperator ? (
                        <p className="text-xs">
                            {t('Υπεκμετάλλευση')}:
                            <span className="font-bold">
                                {' '}
                                {selectedLocationDetails.suboperator}
                            </span>
                        </p>
                    ) : null}
                    {selectedLocationDetails.owner ? (
                        <p className="text-xs">
                            {t('Ιδιοκτησία')}:
                            <span className="font-bold">
                                {' '}
                                {selectedLocationDetails.owner}
                            </span>
                        </p>
                    ) : null}
                </div>

                <img
                    src={`https://electrokinisi.yme.gov.gr/public/images/${selectedLocationDetails.company?.ProfileImage}`}
                    alt=""
                    width="60"
                />
            </div>

            {selectedLocationDetails.opening_times ? (
                <div className="flex flex-row items-center content-center border-b -border-t border-base-200 py-2 gap-2">
                    <OpeningTimesComponent
                        opening_times={selectedLocationDetails.opening_times}
                    />
                </div>
            ) : null}

            {selectedLocationDetails ? (
                <EvseList
                    evses={selectedLocationDetails.evses}
                    className="w-full mt-2 h-full max-h-max overflow-y-auto md:overflow-y-hidden overflow-x-hidden"
                />
            ) : null}

            <div className="flex flex-row items-center content-center w-full mt-auto pt-2 border-t border-base-200 ">
                <>
                    <div className="md:mr-auto btn-group btn-group-horizontal ">
                        <button
                            className="btn tooltip btn-sm md:btn-md"
                            data-tip={t('Εστίαση')}
                            onClick={() =>
                                handleMapZoom(
                                    selectedLocation.geometry.coordinates
                                )
                            }
                        >
                            <FontAwesomeIcon icon={faLocationCrosshairs} />{' '}
                        </button>
                        <button
                            className={`btn tooltip btn-sm md:btn-md ${
                                !userLocation ? 'btn-disabled' : ''
                            }`}
                            data-tip={t('Οδηγίες για το σταθμό')}
                            onClick={() =>
                                getRoute({
                                    origin: [
                                        userLocation.longitude,
                                        userLocation.latitude,
                                    ],
                                    destination:
                                        selectedLocation.geometry.coordinates,
                                })
                            }
                        >
                            <FontAwesomeIcon icon={faDiamondTurnRight} />{' '}
                        </button>
                        <button
                            className="btn tooltip btn-sm md:btn-md flex items-center"
                            data-tip={t('Οδηγίες στο GoogleMaps')}
                            onClick={() => {
                                openGmaps(
                                    userLocation,
                                    selectedLocation.geometry.coordinates
                                )
                            }}
                        >
                            {/* <FontAwesomeIcon icon={faGoogle} className="ml-2" /> */}
                            <img
                                src={GoogleLogo}
                                className="h-6"
                                alt="Google logo"
                            />
                            {t('Οδηγίες')}
                        </button>
                    </div>

                    <span
                        className="ml-auto text-gray-600 mx-2 tooltip tooltip-left font-medium "
                        data-tip={`Ο σταθμός ενημερώθηκε ${lastUpdate}`}
                    >
                        {updatedLongAgo ? (
                            <div className="indicator">
                                <span className="indicator-item">
                                    <FontAwesomeIcon
                                        className="text-orange-500 ml-2"
                                        icon={faExclamationCircle}
                                    />
                                </span>
                                <div className="">
                                    <FontAwesomeIcon
                                        icon={faClockRotateLeft}
                                        className="mr-2"
                                    />
                                    {formatEditedBefore(
                                        selectedLocationDetails.last_updated
                                    )}
                                </div>
                            </div>
                        ) : (
                            <>
                                <FontAwesomeIcon
                                    icon={faClockRotateLeft}
                                    className="mr-2"
                                />
                                {formatEditedBefore(
                                    selectedLocationDetails.last_updated
                                )}
                            </>
                        )}
                    </span>
                </>
            </div>
        </div>
    )
}

//export default React.memo(LocationDetails, arePropsEqual)
export default LocationDetails
export { useMapZoomTo }
