import React from 'react'

function Suspended({ children }) {
    return (
        <React.Suspense fallback={<div>Loading...</div>}>
            {children}
        </React.Suspense>
    )
}

export default Suspended
