import React from 'react'
import { useTranslation } from 'react-i18next'
import { Tariff, TariffElement } from '@/types/CharginStation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBolt, faClock, faReceipt } from '@fortawesome/free-solid-svg-icons'

const tariffTypeLabels = {
    ENERGY: 'Ενέργεια',
    PARKING_TIME: 'Parking',
    FLAT: 'Πάγιο',
    TIME: 'Χρονοχρέωση',
}
const chargeIcon = (chargeType) => {
    switch (chargeType) {
        case 'TIME':
            return <FontAwesomeIcon icon={faClock} className="mr-1" />
        case 'ENERGY':
            return <FontAwesomeIcon icon={faBolt} className="mr-1" />
        case 'PARKING':
            return <FontAwesomeIcon icon={faClock} className="mr-1" />
        case 'FLAT':
            return <FontAwesomeIcon icon={faReceipt} className="mr-1" />
        default:
            return null
    }
}

interface TariffElementProps {
    index?: number
    element: TariffElement
    tariff: Tariff
}
const TariffElementComponent: React.FC<TariffElementProps> = ({
    index,
    element,
    tariff,
}) => {
    const { t } = useTranslation()
    const { price_components } = element
    const currencyLabel = tariff.currency
        ? tariff.currency === 'EUR'
            ? '€'
            : tariff.currency
        : '€'

    const units = (price_component) => {
        if (price_component.type === 'ENERGY') {
            return 'kWh'
        } else if (
            price_component.type === 'TIME' ||
            price_component.type === 'PARKING_TIME'
        ) {
            return 'min'
        } else {
            return null
        }
    }

    const unitsLabel = (price_component) => {
        if (price_component.type === 'ENERGY') {
            return '/kWh'
        } else if (
            price_component.type === 'TIME' ||
            price_component.type === 'PARKING_TIME'
        ) {
            return '/λεπτό'
        } else {
            return null
        }
    }

    const ChargingStep = ({ price_component }) => {
        if (
            !price_component.step_size ||
            price_component.step_size === 1 ||
            price_component.step_size === 1000
        )
            return null
        // We have to account for inconsistent use of Wh and kWh
        // If step size/1000 > 1 then return /{step_size/1000}kWh
        if (
            price_component.step_size / 1000 > 1 &&
            price_component.type === 'ENERGY'
        ) {
            return (
                <span className="text-gray-300 font-bold">
                    *{t('Χρέωση ανά')} {price_component.step_size / 1000}{' '}
                    {t(units(price_component))}
                </span>
            )
        }

        return (
            <span
                className="text-gray-600 font-bold tooltip tooltip-top tooltip-narrow"
                data-tip={t(
                    `Η κατανάλωση σας χρεώνεται ανά ${
                        price_component.step_size
                    } ${t(units(price_component))}`
                )}
            >
                *{t('Χρέωση ανά')} {price_component.step_size}{' '}
                {t(units(price_component))}
            </span>
        )
    }

    return (
        <div className="tabs gap-1 w-full p-0 " key={index}>
            {price_components.map((price_component, idx) => (
                <div
                    key={idx}
                    className="flex flex-col rounded-lg bg-blue-200 p-1 px-2 text-xs font-medium cursor-pointer hover:bg-opacity-70 transition-all"
                >
                    <h4 className="text-slate-400 font-medium">
                        {chargeIcon(price_component.type)}
                        {t(tariffTypeLabels[price_component.type])}{' '}
                    </h4>
                    <span className="text-blue-500 font-bold">
                        {price_component.price} {currencyLabel}
                        {t(unitsLabel(price_component))}
                    </span>
                    <ChargingStep price_component={price_component} />
                </div>
            ))}
        </div>
    )
}

interface TariffComponentProps {
    index?: number
    details: Tariff
}

const TariffComponent: React.FC<TariffComponentProps> = ({ tariff, index }) => {
    const { elements } = tariff
    return (
        <div key={index}>
            <div className="mt-2 border-t border-gray-300 py-1">
                {elements
                    ? elements.map((element, idx) => (
                          <>
                              <TariffElementComponent
                                  index={idx}
                                  tariff={tariff}
                                  element={element}
                              />
                          </>
                      ))
                    : null}
            </div>
        </div>
    )
}

export { TariffComponent, TariffElementComponent }
