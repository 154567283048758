import React, { useContext, useState, useEffect } from 'react'
import { DataContext, NULL_FILTERS } from '@/context/DataContext'

import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilterCircleXmark } from '@fortawesome/free-solid-svg-icons'

/**
 * Clears all filters
 */
function ClearAll() {
    const { t } = useTranslation()
    const { overwriteParams, params } = useContext(DataContext)

    // Initial params, ignoring lng, lat, maxDistance
    const initialFilters = {
        ...NULL_FILTERS,
        lng: params.lng,
        lat: params.lat,
        maxDistance: params.maxDistance,
    }

    const areParamsInitial =
        JSON.stringify(params) === JSON.stringify(initialFilters)

    const clearParams = () => {
        // Keep lng, lat, maxDistance
        overwriteParams({
            ...NULL_FILTERS,
            lng: params.lng,
            lat: params.lat,
            maxDistance: params.maxDistance,
        })
    }

    if (areParamsInitial) return null

    return (
        <div className="clear-all mt-2">
            <button className="btn btn-primary w-full " onClick={clearParams}>
                <FontAwesomeIcon icon={faFilterCircleXmark} className="mr-2" />
                {t('Καθαρισμός')}
            </button>
        </div>
    )
}

export { ClearAll }
