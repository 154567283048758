/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Company } from '../models/Company';
import type { CustomUser } from '../models/CustomUser';
import type { Login } from '../models/Login';
import type { PasswordChange } from '../models/PasswordChange';
import type { PasswordReset } from '../models/PasswordReset';
import type { PasswordResetConfirm } from '../models/PasswordResetConfirm';
import type { Register } from '../models/Register';
import type { ResendEmailVerification } from '../models/ResendEmailVerification';
import type { VerifyEmail } from '../models/VerifyEmail';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AuthService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns Company 
     * @throws ApiError
     */
    public authCompaniesList(): CancelablePromise<Array<Company>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/companies/',
        });
    }

    /**
     * @param data 
     * @returns Company 
     * @throws ApiError
     */
    public authCompaniesCreate(
data: Company,
): CancelablePromise<Company> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/companies/',
            body: data,
        });
    }

    /**
     * @param id A unique integer value identifying this Εταιρεία.
     * @returns Company 
     * @throws ApiError
     */
    public authCompaniesRead(
id: number,
): CancelablePromise<Company> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/companies/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this Εταιρεία.
     * @param data 
     * @returns Company 
     * @throws ApiError
     */
    public authCompaniesUpdate(
id: number,
data: Company,
): CancelablePromise<Company> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/auth/companies/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @param id A unique integer value identifying this Εταιρεία.
     * @param data 
     * @returns Company 
     * @throws ApiError
     */
    public authCompaniesPartialUpdate(
id: number,
data: Company,
): CancelablePromise<Company> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/auth/companies/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @param id A unique integer value identifying this Εταιρεία.
     * @returns void 
     * @throws ApiError
     */
    public authCompaniesDelete(
id: number,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/auth/companies/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns any 
     * @throws ApiError
     */
    public authCsrfList(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/csrf/',
        });
    }

    /**
     * Check the credentials and return the REST Token
 * if the credentials are valid and authenticated.
 * Calls Django Auth login method to register User ID
 * in Django session framework
 *
 * Accept the following POST parameters: username, password
 * Return the REST Framework Token Object's key.
     * @param data 
     * @returns Login 
     * @throws ApiError
     */
    public authLoginCreate(
data: Login,
): CancelablePromise<Login> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/login/',
            body: data,
        });
    }

    /**
     * Calls Django logout method and delete the Token object
 * assigned to the current User object.
     * Accepts/Returns nothing.
     * @returns any 
     * @throws ApiError
     */
    public authLogoutList(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/logout/',
        });
    }

    /**
     * Calls Django logout method and delete the Token object
 * assigned to the current User object.
     * Accepts/Returns nothing.
     * @returns any 
     * @throws ApiError
     */
    public authLogoutCreate(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/logout/',
        });
    }

    /**
     * Redirect to frontend, using uid & token
     * @param uidb64 
     * @param token 
     * @returns any 
     * @throws ApiError
     */
    public authPasswordResetConfirmRead(
uidb64: string,
token: string,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/password-reset/confirm/{uidb64}/{token}/',
            path: {
                'uidb64': uidb64,
                'token': token,
            },
        });
    }

    /**
     * Calls Django Auth SetPasswordForm save method.
     * Accepts the following POST parameters: new_password1, new_password2
 * Returns the success/fail message.
     * @param data 
     * @returns PasswordChange 
     * @throws ApiError
     */
    public authPasswordChangeCreate(
data: PasswordChange,
): CancelablePromise<PasswordChange> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/password/change/',
            body: data,
        });
    }

    /**
     * Calls Django Auth PasswordResetForm save method.
     * Accepts the following POST parameters: email
 * Returns the success/fail message.
     * @param data 
     * @returns PasswordReset 
     * @throws ApiError
     */
    public authPasswordResetCreate(
data: PasswordReset,
): CancelablePromise<PasswordReset> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/password/reset/',
            body: data,
        });
    }

    /**
     * Password reset e-mail link is confirmed, therefore
 * this resets the user's password.
     * Accepts the following POST parameters: token, uid,
 * new_password1, new_password2
 * Returns the success/fail message.
     * @param data 
     * @returns PasswordResetConfirm 
     * @throws ApiError
     */
    public authPasswordResetConfirmCreate(
data: PasswordResetConfirm,
): CancelablePromise<PasswordResetConfirm> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/password/reset/confirm/',
            body: data,
        });
    }

    /**
     * @param data 
     * @returns Register 
     * @throws ApiError
     */
    public authRegistrationCreate(
data: Register,
): CancelablePromise<Register> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/registration/',
            body: data,
        });
    }

    /**
     * Overriding dj_rest_auth ConfirmEmailView (`dj_rest_auth.registration.views.VerifyEmailView`)
 * to parse the token from the request url instead of the request body.
     * @param key 
     * @returns VerifyEmail 
     * @throws ApiError
     */
    public authRegistrationAccountConfirmEmailRead(
key: string,
): CancelablePromise<VerifyEmail> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/registration/account-confirm-email/{key}/',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param data 
     * @returns ResendEmailVerification 
     * @throws ApiError
     */
    public authRegistrationResendEmailCreate(
data: ResendEmailVerification,
): CancelablePromise<ResendEmailVerification> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/registration/resend-email/',
            body: data,
        });
    }

    /**
     * @param data 
     * @returns VerifyEmail 
     * @throws ApiError
     */
    public authRegistrationVerifyEmailCreate(
data: VerifyEmail,
): CancelablePromise<VerifyEmail> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/registration/verify-email/',
            body: data,
        });
    }

    /**
     * Delete existing user token and create a new one
     * @returns any 
     * @throws ApiError
     */
    public authResendTokenList(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/resend-token/',
        });
    }

    /**
     * @returns any 
     * @throws ApiError
     */
    public authSessionList(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/session/',
        });
    }

    /**
     * @returns any 
     * @throws ApiError
     */
    public authUserProfileList(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/user-profile/',
        });
    }

    /**
     * @returns any 
     * @throws ApiError
     */
    public authUserProfileUpdate(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/auth/user-profile/',
        });
    }

    /**
     * @returns void 
     * @throws ApiError
     */
    public authUserProfileDelete(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/auth/user-profile/',
        });
    }

    /**
     * Reads and updates UserModel fields
 * Accepts GET, PUT, PATCH, DELETE methods.
     * Default accepted fields: username, first_name, last_name
 * Default display fields: pk, username, email, first_name, last_name
 * Read-only fields: pk, email
 *
 * Returns UserModel fields.
     * @returns any 
     * @throws ApiError
     */
    public authUserRead(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/user/',
        });
    }

    /**
     * Reads and updates UserModel fields
 * Accepts GET, PUT, PATCH, DELETE methods.
     * Default accepted fields: username, first_name, last_name
 * Default display fields: pk, username, email, first_name, last_name
 * Read-only fields: pk, email
 *
 * Returns UserModel fields.
     * @returns any 
     * @throws ApiError
     */
    public authUserUpdate(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/auth/user/',
        });
    }

    /**
     * Reads and updates UserModel fields
 * Accepts GET, PUT, PATCH, DELETE methods.
     * Default accepted fields: username, first_name, last_name
 * Default display fields: pk, username, email, first_name, last_name
 * Read-only fields: pk, email
 *
 * Returns UserModel fields.
     * @returns any 
     * @throws ApiError
     */
    public authUserPartialUpdate(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/auth/user/',
        });
    }

    /**
     * Reads and updates UserModel fields
 * Accepts GET, PUT, PATCH, DELETE methods.
     * Default accepted fields: username, first_name, last_name
 * Default display fields: pk, username, email, first_name, last_name
 * Read-only fields: pk, email
 *
 * Returns UserModel fields.
     * @returns void 
     * @throws ApiError
     */
    public authUserDelete(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/auth/user/',
        });
    }

    /**
     * @returns CustomUser 
     * @throws ApiError
     */
    public authUsersList(): CancelablePromise<Array<CustomUser>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/users/',
        });
    }

    /**
     * @param data 
     * @returns CustomUser 
     * @throws ApiError
     */
    public authUsersCreate(
data: CustomUser,
): CancelablePromise<CustomUser> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/users/',
            body: data,
        });
    }

    /**
     * @param id A unique integer value identifying this Χρήστης.
     * @returns CustomUser 
     * @throws ApiError
     */
    public authUsersRead(
id: number,
): CancelablePromise<CustomUser> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/users/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this Χρήστης.
     * @param data 
     * @returns CustomUser 
     * @throws ApiError
     */
    public authUsersUpdate(
id: number,
data: CustomUser,
): CancelablePromise<CustomUser> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/auth/users/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @param id A unique integer value identifying this Χρήστης.
     * @param data 
     * @returns CustomUser 
     * @throws ApiError
     */
    public authUsersPartialUpdate(
id: number,
data: CustomUser,
): CancelablePromise<CustomUser> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/auth/users/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @param id A unique integer value identifying this Χρήστης.
     * @returns void 
     * @throws ApiError
     */
    public authUsersDelete(
id: number,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/auth/users/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns any 
     * @throws ApiError
     */
    public authWhoamiList(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/whoami/',
        });
    }

}
