import React, { useContext, useState, useEffect } from 'react'
import { MapContext } from '@/context/MapContext'
import { DataContext } from '@/context/DataContext'
import { useTranslation } from 'react-i18next' // Import react-i18next

function LocationFilter({ ...rest }) {
    const { userLocation } = useContext(MapContext)
    const { setParamsMulti, params, applyDistance, setApplyDistance } =
        useContext(DataContext)
    const { latitude, longitude } = userLocation || {
        latitude: null,
        longitude: null,
    }
    const { lat, lng, maxDistance } = params || {
        lat: null,
        lng: null,
        maxDistance: null,
    }

    const [distance, setDistance] = useState(maxDistance / 1000 || 100)
    const { t } = useTranslation() // Initialize the translation function

    useEffect(() => {
        if (applyDistance) {
            if (latitude && longitude && distance) {
                setParamsMulti([
                    { name: 'lat', value: latitude },
                    { name: 'lng', value: longitude },
                    { name: 'maxDistance', value: distance * 1000 },
                ])
            }
        } else {
            setParamsMulti([
                { name: 'lat', value: null },
                { name: 'lng', value: null },
                { name: 'maxDistance', value: null },
            ])
        }
    }, [applyDistance, distance, latitude, longitude])

    return (
        <div className="rounded-lg p-1 " {...rest}>
            <div className="form-control ">
                <label className="label cursor-pointer">
                    <span className="text-xs  font-normal">
                        {t('Απόσταση από εμένα')}{' '}
                    </span>
                    <input
                        type="checkbox"
                        checked={applyDistance || false}
                        className={`checkbox checkbox-success checkbox-xs ${
                            userLocation === null ? 'disabled' : ''
                        }`}
                        onChange={
                            userLocation === null
                                ? () => {}
                                : (e) => {
                                      setApplyDistance(!applyDistance)
                                  }
                        }
                        disabled={userLocation === null}
                    />
                </label>
                {!userLocation ? (
                    <label className="text-xs opacity-60">
                        {t('Πρέπει να ενεργοποιήσετε την τοποθεσία σας')}{' '}
                    </label>
                ) : null}
            </div>

            {applyDistance ? (
                <div className="flex flex-col">
                    <label className="label text-sm">
                        {t('Μέγιστη απόσταση')}
                        <span className="font-bold">{distance} Km</span>
                    </label>
                    <input
                        type="range"
                        min="0"
                        max="500"
                        value={distance}
                        className="range range-xs range-secondary"
                        onChange={(e) => {
                            setDistance(e.target.value)
                        }}
                    />
                </div>
            ) : null}
        </div>
    )
}

export { LocationFilter }
