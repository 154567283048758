import React from 'react'
import { DashboardListItem } from '@/components/dashboard/DashboardListItem'
import { shallowEqual } from '@/lib/utils'

const arePropsEqual = (prevProps, nextProps) => {
    return shallowEqual(prevProps.sortedData, nextProps.sortedData) && prevProps.sortingOption == nextProps.sortingOption
}

const _LocationsList = ({ sortedData, setSelectedLocation, sortingOption }) => {
    if (!sortedData) return null

    return (
        <div
            className={`grid grid-cols-1 gap-1 overflow-y-auto overflow-x-hidden  rounded-lg p-2 h-full `}
        >
            {sortedData?.map((location, index) => {
                /* if (!location.properties.charging_cost) return null */
                return (
                    <DashboardListItem
                        key={index}
                        location={location}
                        setSelected={setSelectedLocation}
                    />
                )
            })}
        </div>
    )
}

//[sortedData, selectedLocation])

export default React.memo(_LocationsList, arePropsEqual)
