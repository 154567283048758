import React from 'react'
import { EVSE } from '@/types/CharginStation'
import ConnectorDetails from '@/components/connectors/ConnectorDetails'

import {
    formatDateTime,
    formatEditedBefore,
    alertTimeElapsed,
} from '@/lib/utils'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faChargingStation,
    faRotate,
    faCircleCheck,
    faCheck,
    faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next' // Import the translation hook

interface Props {
    evse: EVSE
    index: number
}

const EvseDetails: React.FC<Props> = ({ evse, index, ...rest }) => {
    const lastUpdate = formatDateTime(evse.last_updated)
    const updatedLongAgo = alertTimeElapsed(evse.last_updated, {
        minutes: 30,
    })

    const { t } = useTranslation() // Translation hook

    return (
        <div className="w-full h-full min-h-max p-3 pb-1 mb-1 bg-base-200 bg-opacity-30 rounded-lg ">
            <div className="flex flex-row w-full">
                <div className="indicator">
                    <span
                        className={`indicator-item badge badge-xs ${
                            evse.status === 'AVAILABLE'
                                ? 'badge-success'
                                : 'badge-warning'
                        } ml-2`}
                    >
                        {t(evse.status)}
                    </span>
                    <h2 className="md:prose">
                        <FontAwesomeIcon icon={faChargingStation} />
                        &nbsp; {t('Φορτιστής')} {index}
                    </h2>
                </div>

                {evse.last_updated ? (
                    <span
                        className="ml-auto text-gray-600 mx-2 tooltip tooltip-left font-medium"
                        data-tip={`${t(
                            'Ο φορτιστής ενημερώθηκε'
                        )} ${lastUpdate}`}
                    >
                        {updatedLongAgo ? (
                            <div className="indicator">
                                <span className="indicator-item">
                                    <FontAwesomeIcon
                                        className="text-orange-600 ml-2"
                                        icon={faExclamationCircle}
                                    />
                                </span>
                                <div className="">
                                    <FontAwesomeIcon
                                        icon={faRotate}
                                        className="mr-2"
                                    />
                                    {formatEditedBefore(evse.last_updated)}
                                </div>
                            </div>
                        ) : (
                            <>
                                <FontAwesomeIcon
                                    icon={faRotate}
                                    className="mr-2"
                                />
                                {formatEditedBefore(evse.last_updated)}
                            </>
                        )}
                    </span>
                ) : null}

                {evse.charging_cost ? (
                    <span className="ml-auto text-secondary text-lg font-bold mx-2">
                        <span className="font-normal text-xs">
                            {t('Μέση χρέωση')} &nbsp;{' '}
                        </span>
                        {evse.charging_cost} €/kWh
                    </span>
                ) : null}
            </div>

            <div className="tabs  gap-2 w-full text-xs text-gray-400 font-normal">
                {evse.manufacturer ? (
                    <div className="">
                        {t('Κατασκευαστής')}:{' '}
                        <strong> {evse.manufacturer} </strong>
                    </div>
                ) : null}
                {evse.model_name ? (
                    <div>
                        {t('Μοντέλο')}: <strong> {evse.model_name} </strong>
                    </div>
                ) : null}
                {evse.floor_level ? (
                    <div>
                        {t('Όροφος')}:{' '}
                        <strong>
                            {' '}
                            {evse.floor_level == 0
                                ? t('Ισόγειο')
                                : evse.floor_level}{' '}
                        </strong>
                    </div>
                ) : null}
                {evse.supports_roaming && evse.supports_roaming == true ? (
                    <div>
                        {t('Roaming')}:
                        <FontAwesomeIcon
                            className="text-green-700 ml-2"
                            icon={faCircleCheck}
                        />
                    </div>
                ) : null}
            </div>

            <h3 className="ml-1 mt-1 text-gray-500 font-bold border-t border-gray-300 py-1 hidden md:block">
                {t('Δυνατότητες')}
            </h3>

            <div className="w-full hidden md:block">
                {evse.capabilities && evse.capabilities.length > 0
                    ? evse.capabilities.map((capability, index) => (
                          <span
                              className="badge badge-xs m-1 border border-gray-300"
                              key={index}
                          >
                              <FontAwesomeIcon icon={faCheck} /> {t(capability)}
                          </span>
                      ))
                    : null}
            </div>

            <h3 className="ml-1 mt-4 text-gray-500 font-bold border-t border-gray-300 py-1">
                <span className="hidden md:block">
                    {t('Διαθέσιμοι Ρευματοδότες')}
                </span>
            </h3>

            <div className="lg:max-h-none overflow-y-hidden  lg:overflow-hidden overflow-x-hidden lg:grid lg:grid-cols-2 lg:gap-1">
                {evse.connectors.map((connector, index) => (
                    <div
                        key={index}
                        className="h-content rounded-lg bg-[#dae9ef] border border-gray-300  my-1  p-1 px-3"
                    >
                        <ConnectorDetails
                            connector={connector}
                            index={index + 1}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default EvseDetails
