import React, { useContext, useState } from 'react'
import { AuthContext } from '@/context/AuthContext'
import { set, useForm } from 'react-hook-form'
import RouteBase from '@/routes/RouteBase'
import LogoImg from '@/assets/img/favicon.webp'
import { useNavigate, Link, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faSpinner } from '@fortawesome/free-solid-svg-icons'

const PasswordResetConfirm = () => {
    const { AuthenticatedAPI, error, setError } = useContext(AuthContext)
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm()

    const ApiError = error

    const { uid, token } = useParams()

    const setNewPassword = (data) => {
        const { new_password1, new_password2 } = data
        setLoading(true)
        AuthenticatedAPI.auth
            .authPasswordResetConfirmCreate({
                new_password1,
                new_password2,
                uid,
                token,
            })

            .then((response) => {
                setSuccess(true)
                setError(null)
                setLoading(false)
            })
            .catch((err) => {
                console.log('error', err.body)
                setError(err.body)
                setLoading(false)
            })
    }

    const SuccessMessageComponent = () => {
        return (
            <>
                {success && (
                    <div className="flex flex-row items-center mt-3 text-sm text-green-700 font-bold">
                        <FontAwesomeIcon
                            icon={faCheckCircle}
                            className=" mr-3"
                        />
                        <span>Ο κωδικός πρόσβασης άλλαξε επιτυχώς</span>
                    </div>
                )}
            </>
        )
    }

    const SubmitButtonComponent = () => {
        return (
            <button type="submit" className="mt-4 btn btn-outline mr-auto">
                {loading && (
                    <FontAwesomeIcon
                        icon={faSpinner}
                        className="animate-spin mr-2"
                    />
                )}
                Αλλαγή κωδικού
            </button>
        )
    }

    const FormComponent = () => {
        return (
            <form
                onSubmit={handleSubmit(setNewPassword)}
                className="flex flex-col p-5 px-7 "
            >
                <h1 className=" text-xl  my-4">Ανάκτηση κωδικού πρόσβασης </h1>

                <div className="form-group mb-5">
                    <label
                        htmlFor="new_password1"
                        className={`font-medium  ${success && 'opacity-60'}`}
                    >
                        Νέος Κωδικός Πρόσβασης
                    </label>
                    <input
                        {...register('new_password1', {
                            required: 'This field is required.',
                        })}
                        type="password"
                        placeholder="*****"
                        className="w-full input input-bordered input-primary"
                        disabled={success}
                        id="new_password1"
                        name="new_password1"
                    />

                    {errors.new_password1 && (
                        <small className="text-error">
                            {errors.new_password1.message}
                        </small>
                    )}
                    {ApiError?.new_password1 && (
                        <small className="text-error">
                            {ApiError.new_password1.message}
                        </small>
                    )}
                </div>
                <div className="form-group mb-5">
                    <label
                        htmlFor="new_password2"
                        className={`font-medium  ${success && 'opacity-60'}`}
                    >
                        Νέος Κωδικός Πρόσβασης (Επιβεβαίωση)
                    </label>
                    <input
                        {...register('new_password2', {
                            required: 'This field is required.',
                        })}
                        type="password"
                        placeholder="*****"
                        className="w-full input input-bordered input-primary"
                        disabled={success}
                        id="new_password2"
                        name="new_password2"
                    />

                    {errors.new_password2 && (
                        <small className="text-error">
                            {errors.new_password2}
                        </small>
                    )}
                    {ApiError?.new_password2 && (
                        <small className="text-error">
                            {ApiError.new_password2}
                        </small>
                    )}
                </div>

                {ApiError?.uid && (
                    <small className="text-error">uid: {ApiError.uid}</small>
                )}
                {ApiError?.token && (
                    <small className="text-error">
                        reset-token: {ApiError.token}
                    </small>
                )}
                {ApiError?.non_field_errors &&
                    ApiError.non_field_errors.map((err, index) => {
                        return (
                            <small className="text-error" key={index}>
                                {err}
                            </small>
                        )
                    })}

                {success ? (
                    <SuccessMessageComponent />
                ) : (
                    <SubmitButtonComponent />
                )}

                <div className="mt-4 ml-auto">
                    <Link
                        to="/login"
                        className="link text-blue-900 font-medium text-sm mt-4 ml-auto"
                    >
                        Επιστροφή στην σελίδα σύνδεσης
                    </Link>
                </div>
            </form>
        )
    }

    return (
        <RouteBase classNames="flex flex-col content-center items-center h-full">
            <div className="h-full w-full flex flex-col">
                <div className="m-auto rounded-lg bg-blue-100 bg-opacity-20 border border-blue-100 p-4 flex  flex-col gap-3 ">
                    <div className="h-content flex flex-col md:flex-row ">
                        <img
                            src={LogoImg}
                            alt=""
                            width="150"
                            className="m-auto"
                        />

                        <div className="divider lg:divider-horizontal"></div>

                        <FormComponent />
                    </div>
                </div>
            </div>
        </RouteBase>
    )
}

export default PasswordResetConfirm
