import React from 'react'
import { EVSE } from '@/types/CharginStation'
import EvseDetails from './EvseDetails'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChargingStation, faPlug } from '@fortawesome/free-solid-svg-icons'

interface Props {
    evses: EVSE[]
}

function getMinChargingCost(evse: EVSE) {
    if (!evse?.connectors || evse?.connectors.length === 0) return null

    const cheapestConnector = evse?.connectors?.reduce((prev, curr) => {
        if (!prev) return curr
        if (!curr) return prev

        const prevCost = prev.charging_cost.mean_cost
        const currCost = curr.charging_cost.mean_cost

        if (prevCost === undefined || !prevCost) return curr
        if (currCost === undefined || !currCost) return prev

        return prevCost < currCost ? prev : curr
    })

    return cheapestConnector.charging_cost.mean_cost
}

const EvseList: React.FC<Props> = ({ evses, ...rest }) => {
    if (!evses) return null

    evses = evses.map((evse: EVSE) => {
        const min_charging_cost = getMinChargingCost(evse)

        return {
            ...evse,
            connectors: evse.connectors?.sort((a, b) => {
                return a?.charging_cost.mean_cost < b?.charging_cost.mean_cost
                    ? 1
                    : -1
            }),

            // Get the minimum charging cost
            min_charging_cost: min_charging_cost,
        }
    })

    // Sort by min_charging_cost, status=AVAILABLE first
    evses = evses.sort((a, b) => {
        if (a?.status === 'AVAILABLE' && b?.status !== 'AVAILABLE') return -1
        if (a?.status !== 'AVAILABLE' && b?.status === 'AVAILABLE') return 1

        if (a?.min_charging_cost && !b?.min_charging_cost) return -1
        if (!a?.min_charging_cost && b?.min_charging_cost) return 1

        if (a?.min_charging_cost && b?.min_charging_cost) {
            return a?.min_charging_cost < b?.min_charging_cost ? -1 : 1
        }

        return 0
    })

    return (
        <div {...rest}>
            <Tabs className="w-full h-full">
                <TabList className="tabs mb-2 sticky mr-auto mt-1">
                    {evses.map((evse: EVSE, index) => (
                        <Tab
                            key={index}
                            className="tab tab-sm rounded-lg border bg-base-100 py-1 mx-[1px]"
                        >
                            <div
                                className={` ${
                                    evse?.status === 'AVAILABLE'
                                        ? 'text-success'
                                        : 'text-warning'
                                }`}
                            >
                                <FontAwesomeIcon icon={faChargingStation} />
                            </div>
                            &nbsp;
                            <div className="text-xs font-medium">
                                {evse.min_charging_cost} €/kWh
                            </div>
                        </Tab>
                    ))}
                </TabList>

                {evses.map((evse: EVSE, index) => (
                    <TabPanel key={index} className="tab-content">
                        <EvseDetails evse={evse} index={index + 1} />
                    </TabPanel>
                ))}
            </Tabs>
        </div>
    )
}

export default EvseList
