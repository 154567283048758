import React, { useContext, useState, useEffect } from 'react'
import { DataContext } from '@/context/DataContext'
import { useTranslation } from 'react-i18next' // Import react-i18next

function ChargeAmount({ size = 'xs', ...rest }) {
    const { setParamsMulti, chargeMode, setChargeMode, params } =
        useContext(DataContext)
    const { asked_kwh, asked_euros } = params || {
        asked_kwh: null,
        asked_euros: null,
        asked_duration: null,
    }
    const [chargeAmount, setChargeAmount] = useState(
        asked_kwh || asked_euros || 50
    )
    const { t } = useTranslation() // Initialize the translation function

    useEffect(() => {
        setParamsMulti([
            {
                name: 'asked_kwh',
                value: chargeMode === 'kwh' ? chargeAmount : null,
            },
            {
                name: 'asked_euros',
                value: chargeMode === 'euros' ? chargeAmount : null,
            },
            {
                name: 'asked_duration',
                value: chargeMode === 'minutes' ? chargeAmount : null,
            },
        ])
    }, [chargeMode, chargeAmount])

    const placeholder = () => {
        switch (chargeMode) {
            case 'kwh':
                return t('Ποσό σε kWh') // Use t() to translate
            case 'euros':
                return t('Ποσό σε €') // Use t() to translate
            case 'minutes':
                return t('Ποσό σε λεπτά') // Use t() to translate
            default:
                return t('Ποσό σε kWh') // Use t() to translate
        }
    }

    return (
        <div className="w-full p-2" {...rest}>
            <h3 className={`text-${size}`}> {t('Θέλω να φορτίσω:')}</h3>{' '}
            {/* Use t() to translate the title */}
            <div className="flex flex-col md:flex-row items-baseline content-center mt-2">
                <div className="form-control w-full flex flex-row mx-1">
                    <label className={`input-group input-group-${size}`}>
                        <input
                            placeholder={placeholder()}
                            className={`input input-${size} input-bordered w-full text-black`}
                            type="number"
                            step={1}
                            name="chargeAmount"
                            id="chargeAmount"
                            value={chargeAmount}
                            onChange={(e) => {
                                if (e.target.value < 0) e.target.value = 0
                                setChargeAmount(e.target.value)
                            }}
                        />

                        <select
                            className={`select select-bordered text-black join-item select-${size} transition-all`}
                            value={chargeMode}
                            onChange={(e) => setChargeMode(e.target.value)}
                        >
                            <option value="kwh">kWh</option>
                            <option value="euros">€</option>
                            <option value="minutes">{t('λεπτά')}</option>
                        </select>
                    </label>
                </div>
            </div>
        </div>
    )
}

export { ChargeAmount }
