import React from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDays, faClock } from '@fortawesome/free-solid-svg-icons'
import { OpeningTimes } from '@/api'
import { RegularHour } from '@/types/RegularHour'

/**
 *
 * @param day (1-7)
 * @returns string
 */
const getDayName = (day: number) => {
    const { t } = useTranslation()
    switch (day) {
        case 1:
            return t('Δευτέρα')
        case 2:
            return t('Τρίτη')
        case 3:
            return t('Τετάρτη')
        case 4:
            return t('Πέμπτη')
        case 5:
            return t('Παρασκευή')
        case 6:
            return t('Σάββατο')
        case 7:
            return t('Κυριακή')
    }
}

// get the current weekday (1-7)
const getDay = () => {
    const now = new Date()
    return now.getDay()
}

/**
 * Check if the location is open at the moment
 * @param opening_times
 * @returns boolean
 *
 */
const checkIsOpen = (opening_times: OpeningTimes) => {
    const now = new Date()
    const day = now.getDay()
    const hours = now.getHours()
    const minutes = now.getMinutes()

    const { twentyfourseven, regular_hours } = opening_times[0]

    if (twentyfourseven) return true

    if (regular_hours) {
        const current_day = regular_hours.find(
            (itinerary_item: RegularHour) => itinerary_item.weekday === day
        )

        if (current_day) {
            const current_time = parseInt(
                `${hours}${minutes < 10 ? '0' + minutes : minutes}`
            )
            const period_begin = parseInt(
                current_day.period_begin.replace(':', '')
            )
            const period_end = parseInt(current_day.period_end.replace(':', ''))

            // Special case: if period ends on midnight, add 2400 to period_end
            if (period_end === 0) {
                if (current_time >= period_begin && current_time <= 2400) {
                    return true
                }
            }

            if (current_time >= period_begin && current_time <= period_end) {
                return true
            }
        }
    }

    return false
}

/**
 * Check if the location is closing in the next 1 hour,
 * and return the minutes left until closing
 *
 * @param opening_times OpeningTimes
 * @returns boolean | number
 */
const checkIsClosingSoon = (opening_times: OpeningTimes) => {
    const now = new Date()
    const day = now.getDay()
    const hours = now.getHours()
    const minutes = now.getMinutes()

    const { twentyfourseven, regular_hours } = opening_times[0]

    if (twentyfourseven) return false

    if (regular_hours) {
        const current_day = regular_hours.find(
            (itinerary_item: RegularHour) => itinerary_item.weekday === day
        )

        if (current_day) {
            const current_time = parseInt(
                `${hours}${minutes < 10 ? '0' + minutes : minutes}`
            )
            const period_end = parseInt(current_day.period_end.replace(':', ''))

            if (current_time >= period_end - 60 && current_time <= period_end) {
                return period_end - current_time
            }
        }
    }

    return false
}

interface OpeningTimesComponentProps {
    opening_times: OpeningTimes
}

const OpeningTimesComponent: React.FC<OpeningTimesComponentProps> = ({
    opening_times,
}) => {
    const { t } = useTranslation() // Initialize the translation function
    if (!opening_times) return null
    if (opening_times.length === 0) return null
    const {
        twentyfourseven,
        exceptional_openings,
        exceptional_closings,
        regular_hours,
    } = opening_times[0]

    const isOpen = checkIsOpen(opening_times)
    const isClosingSoon = checkIsClosingSoon(opening_times)

    const OpenIndicator = () => {
        if (isClosingSoon) {
            return (
                <>
                    <span className="bg-yellow-500 h-2 w-2 mask mask-circle mr-2"></span>
                    <span>
                        {t('Κλείνει σύντομα')}{' '}
                        <span className="font-medium text-gray-600">
                            ({isClosingSoon} {t('λεπτά')})
                        </span>
                    </span>
                </>
            )
        }
        if (isOpen) {
            return (
                <>
                    <span className="bg-green-600 h-2 w-2 mask mask-circle mr-2"></span>
                    <span>{t('Ανοιχτό')} </span>
                </>
            )
        }
        return (
            <>
                <span className="bg-red-600 h-2 w-2 mask mask-circle mr-2"></span>
                <span>{t('Κλειστό')} </span>
            </>
        )
    }

    const RegularHoursComponent = () => {
        return (
            <>
                <div className="collapse collapse-arrow  rounded-box w-full">
                    <input type="checkbox" />
                    <div className="collapse-title text-xs font-bold flex flex-row items-center">
                        <div className="mr-20">
                            <div className="flex flex-row items-center">
                                <OpenIndicator />
                            </div>
                        </div>
                        <div className="ml-auto">
                            <FontAwesomeIcon
                                icon={faCalendarDays}
                                className="mr-2"
                            />
                            {t('Ωράριο Λειτουργίας')}
                        </div>
                    </div>
                    <div className="collapse-content">
                        <table className="w-full ">
                            <tbody>
                                {regular_hours
                                    .sort((a, b) => a.weekday - b.weekday)
                                    .map(
                                        (
                                            itinerary_item: RegularHour,
                                            index: number
                                        ) => (
                                            <tr
                                                key={index}
                                                className={
                                                    getDay() ===
                                                    itinerary_item.weekday
                                                        ? 'bg-blue-100 '
                                                        : ''
                                                }
                                            >
                                                <th className="flex flex-row items-center content-center mx-3">
                                                    <FontAwesomeIcon
                                                        icon={faClock}
                                                        className="mr-2"
                                                    />
                                                    {getDayName(
                                                        itinerary_item.weekday
                                                    )}
                                                    :{' '}
                                                </th>
                                                <td className="text-blue-500 font-medium">
                                                    {
                                                        itinerary_item.period_begin
                                                    }{' '}
                                                    -{' '}
                                                    {itinerary_item.period_end}
                                                </td>
                                                {getDay() ===
                                                itinerary_item.weekday ? (
                                                    <td className=" text-blue-300 font-bold">
                                                        {t('Σήμερα')}
                                                    </td>
                                                ) : null}
                                            </tr>
                                        )
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        )
    }

    const TwentyfourSeven = () => {
        return (
            <div className="flex flex-row items-center  w-full">
                <div className="flex flex-row items-center font-bold">
                    <OpenIndicator />
                </div>
                <div className="flex flex-row items-center content-center ml-auto ">
                    <FontAwesomeIcon icon={faClock} className="mr-2" />
                    <span className="font-bold">{t('Λειτουργεί 24/7')}</span>
                </div>
            </div>
        )
    }

    return (
        <>
            {twentyfourseven ? <TwentyfourSeven /> : null}
            {regular_hours ? <RegularHoursComponent /> : null}
        </>
    )
}

export { OpeningTimesComponent }
