import { useTranslation } from 'react-i18next'

function Disclaimer() {
    const { t } = useTranslation()
    return (
        <div className="prose text-black">
            <p>{t('disclaimer.p1')}</p>
            <p>
                {t('disclaimer.p2a')}
                <a
                    className="link text-blue-900"
                    href="https://electrokinisi.yme.gov.gr/public"
                    target="_blank"
                >
                    Μ.Η.Φ.Α.Υ.
                </a>{' '}
                {t('disclaimer.p2b')}
            </p>
            <p>{t('disclaimer.p3')}</p>
            <p>{t('disclaimer.p4')}</p>
            <p>{t('disclaimer.p5')}</p>
        </div>
    )
}

export { Disclaimer }
