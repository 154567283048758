import React, { useContext, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCrosshairs,
    faExclamationTriangle,
    faInfoCircle,
    faLocationCrosshairs,
    faSpinner,
} from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

import { MapContext } from '@/context/MapContext'

function Speed_to_Kmh(speed) {
    return (speed * 3.6).toFixed(2)
}

function MyLocation({ expanded = true, className, ...rest }) {
    const { t } = useTranslation()
    const {
        currentMap,
        userLocation,
        userAddress,
        locationLoading,
        locationError,
        startTracking,
    } = useContext(MapContext)
    const [accuracyClass, setAccuracyClass] = useState('text-primary')

    // gently zoom to user's location
    const zoomToUser = () => {
        if (!userLocation) return
        if (!currentMap) return

        currentMap.flyTo({
            center: [userLocation.longitude, userLocation.latitude],
            zoom: 15,
        })
    }

    useEffect(() => {
        if (!userLocation) return

        if (userLocation.accuracy < 10) {
            setAccuracyClass('text-green-400')
        } else if (userLocation.accuracy < 20) {
            setAccuracyClass('text-orange-400')
        } else {
            setAccuracyClass('text-red-400')
        }
    }, [userLocation])

    const ZoomToUserButton = () => {
        if (!currentMap || currentMap === null) return
        return (
            <div className="flex flex-row mt-4 w-full">
                <button
                    className="transition-all hover:ring-2 ring-blue-400 rounded-md p-1 ml-auto text-xs font-bold text-blue-400"
                    onClick={zoomToUser}
                >
                    <FontAwesomeIcon icon={faCrosshairs} className="mr-1" />
                    {t('Εστίαση')}
                </button>
            </div>
        )
    }

    // Loading
    if (locationLoading) {
        return (
            <div className={'p-2 ' + className}>
                <p className="text-xs ">
                    <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
                    {t('Φορτώνει')}
                </p>
                <p className="text-xs mt-2 text-gray-500">
                    <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="mr-2 text-blue-100"
                    ></FontAwesomeIcon>
                    {t(
                        'Επιτρέψτε στην εφαρμογή να αποκτήσει πρόσβαση στη θέση της συσκευής σας'
                    )}
                </p>
            </div>
        )
    }

    // Set
    if (userLocation) {
        return (
            <>
                <div
                    className={
                        'collapse collapse-arrow rounded-lg  ' + className
                    }
                    style={{
                        visibility: expanded ? 'visible' : 'inherit',
                    }}
                >
                    <input type="checkbox" />
                    <div className="collapse-title overflow-hidden truncate ">
                        <span className="text-xs font-extrabold text-green-300  w-full">
                            <FontAwesomeIcon
                                icon={faLocationCrosshairs}
                                className="mr-2"
                            />
                            {t('Η θέση μου')}
                        </span>
                        <br />
                        {userAddress ? (
                            <span
                                className="text-xs mt-2 truncate text-left w-[90%]"
                                data-tip={userAddress.display_name}
                            >
                                {userAddress.display_name}
                            </span>
                        ) : null}
                    </div>
                    <div className="collapse-content ">
                        <div className="flex flex-col w-content m-auto ">
                            {userAddress ? (
                                <p
                                    className="text-xs mt-2 font-normal text-gray-400 text-left"
                                    data-tip={userAddress.display_name}
                                >
                                    {userAddress.display_name}
                                    {' ('}
                                    <a
                                        className="text-blue-200 hover:underline"
                                        target="_blank"
                                        href="https://nominatim.openstreetmap.org/ui/search.html"
                                    >
                                        Nominatim
                                    </a>
                                    {')'}
                                </p>
                            ) : null}

                            <div className="border-t  my-2"></div>

                            <p className="text-xs mt-1 ">
                                {t('Ακρίβεια')}:
                                <span className={accuracyClass}>
                                    {accuracyClass === 'text-red-400' ? (
                                        <FontAwesomeIcon
                                            icon={faExclamationTriangle}
                                            className="ml-2 mr-1"
                                        />
                                    ) : null}
                                    {userLocation.accuracy
                                        ? userLocation.accuracy.toFixed(2)
                                        : '?'}{' '}
                                    m
                                </span>
                            </p>
                            {userLocation.speed ? (
                                <p className="text-xs mt-1 text-orange-100">
                                    {t('Ταχύτητα')}:{' '}
                                    {Speed_to_Kmh(userLocation.speed)} m/s
                                </p>
                            ) : null}
                            {userLocation.altitude ? (
                                <p className="text-xs mt-1 text-orange-100">
                                    {t('Υψόμετρο')}: {userLocation.altitude} m
                                </p>
                            ) : null}

                            <ZoomToUserButton />
                        </div>
                    </div>
                </div>
            </>
        )
    }

    // Not Set
    return (
        <div className={'p-2 ' + className}>
            <p className="text-xs font-bold">{t('Τοποθεσία Ανενεργή')}</p>

            <p className="text-xs text-gray-500 mt-2">
                <FontAwesomeIcon
                    icon={faInfoCircle}
                    className="mr-2 text-blue-300"
                ></FontAwesomeIcon>
                {t(
                    'Ενεργοποιείστε την τοποθεσία σας για να βελτιώσετε τα αποτελέσματα της αναζήτησης και να δείτε προσεγγιστικές αποστάσεις προς τους σταθμούς φόρτισης'
                )}
            </p>

            <button
                className="btn btn-xs btn-info btn-outline mt-3"
                onClick={startTracking}
            >
                <FontAwesomeIcon icon={faCrosshairs} className="mr-2" />
                {t('Ενεργοποίηση')}
            </button>
            {locationError ? (
                <div className="mt-2 pt-1 text-xs text-red-300 font-extralight border-t border-gray-800">
                    <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className="mr-2"
                    />
                    {t('Σφάλμα')}: {locationError.message}
                </div>
            ) : null}
        </div>
    )
}

export default MyLocation
