import React from 'react'
import RaaeyLogoGr from '@/assets/img/raaey_logo_gr.webp'
import RaaeyLogoEn from '@/assets/img/raaey_logo_en.webp'

function Footer() {
    const RaeLogo = t('i18n.language') === 'el' ? RaaeyLogoGr : RaaeyLogoEn

    return (
        <footer className="footer items-center p-4 bg-neutral text-neutral-content">
            <div className="items-center grid-flow-col">
                <img src={RaeLogo} alt="Placeholder" className="h-20 m-auto" />
                <p>Copyright © 2023 - All right reserved</p>
            </div>
            <div className="grid-flow-col gap-4 md:place-self-center md:justify-self-end"></div>
        </footer>
    )
}

export default Footer
