import React from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faBarsStaggered } from '@fortawesome/free-solid-svg-icons'

function SidebarToggle({ toggleSidebar, isExpanded, ...rest }) {
    const { t } = useTranslation()
    return (
        <button
            className="btn btn-circle btn-ghost shadow-lg tooltip tooltip-right"
            data-tip={
                isExpanded
                    ? t('Ελαχιστοποίηση Εργαλειοθήκης')
                    : t('Άνοιγμα Εργαλειοθήκης')
            }
            onClick={toggleSidebar}
            {...rest}
        >
            <FontAwesomeIcon icon={isExpanded ? faBarsStaggered : faBars} />
        </button>
    )
}

export default SidebarToggle
