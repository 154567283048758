import React, {
    createContext,
    useState,
    useRef,
    useEffect,
    useCallback,
} from 'react'
import { point, distance } from '@turf/turf'

const MapContext = createContext()

function locationEqual(l1, l2) {
    if (!l1 || !l2) return false
    if (l1.longitude !== l2.longitude) return false
    if (l1.latitude !== l2.latitude) return false
    return true
}

function locationDistance(l1, l2) {
    if (!l1 || !l2) return null
    return distance(
        point([l1.longitude, l1.latitude]),
        point([l2.longitude, l2.latitude])
    )
}

function locationDidMove(l1, l2) {
    if (!l1 || !l2) return true
    if (locationEqual(l1, l2)) return false

    const distance = locationDistance(l1, l2)
    if (distance > 0.05) return true

    return false
}

/*
 * Set the map style based on the time of day
 * @returns {string} - the map style name
 */
function styleBasedOnTime() {
    const date = new Date()
    const hour = date.getHours()
    if (hour >= 6 && hour < 18) {
        return 'basic'
    } else {
        return 'black'
    }
}

function MapProvider(props) {
    const mapContainer = useRef(null)
    const [currentMap, setCurrentMap] = useState(null)
    const [loading, setLoading] = useState(true)

    // Map style
    const [mapStyle, setMapStyle] = useState(styleBasedOnTime())

    // View (map or list)
    const [showMap, setShowMap] = useState(false)

    // User location:
    // - browserLocation: the location from the browser's geolocation API
    // - userLocation: the location we use for the app. If the user moved more than 50 meters
    //   from the previous location, we update this value
    const [_browserLocation, _setBrowserLocation] = useState(null)
    const [userLocation, setUserLocation] = useState(null)

    const watchId = useRef(null)

    const [userAddress, setUserAddress] = useState(null)
    const [isTracking, setIsTracking] = useState(false)
    const [locationLoading, setLocationLoading] = useState(false)
    const [locationError, setLocationError] = useState(null)
    const [firstTracking, setFirstTracking] = useState(true)
    const [prevGeocodedLocation, setPrevGeocodedLocation] = useState(null)

    // only set user location if different than the previous,
    // and more than 50 meters away
    useEffect(() => {
        if (!locationDidMove(_browserLocation, userLocation)) return
        setUserLocation(_browserLocation)
    }, [_browserLocation])

    const startTracking = useCallback(() => {
        if (isTracking) return
        setLocationLoading(true)

        const options = {
            enableHighAccuracy: true,
            timeout: 5000,
            // accept cached location if it's not older than 2 minutes
            maximumAge: 120000,
        }

        const success = (position) => {
            if (locationDidMove(_browserLocation, position.coords)) {
                _setBrowserLocation(position.coords)
            }

            if (locationError) setLocationError(null)
        }

        const error = (error) => {
            if (!!locationLoading) setLocationLoading(false)
            if (!firstTracking) {
                return
            }

            console.error('Error getting location:', error.message)
            setLocationError(error.message)
        }

        watchId.current = navigator.geolocation.watchPosition(
            success,
            error,
            options
        )

        return () => {
            navigator.geolocation.clearWatch(watchId)
            watchId.current = null
            setIsTracking(false)
        }
    }, [isTracking])

    useEffect(() => {
        if (!!locationLoading) setLocationLoading(false)
        if (!isTracking) setIsTracking(true)
    }, [userLocation, locationError])

    useEffect(() => {
        setFirstTracking(false)
    }, [userLocation])

    // When tracking first starts, zoom to the user's position
    useEffect(() => {
        if (firstTracking === false) return
        if (!userLocation) return
        if (!currentMap) return

        const { latitude, longitude } = userLocation
        currentMap.flyTo({ center: [longitude, latitude], zoom: 14 })
        setFirstTracking(false)
    }, [userLocation, currentMap])

    // When the user location changes, request an address from nominatim
    useEffect(() => {
        if (!userLocation) return
        if (!currentMap) return

        if (!locationDidMove(prevGeocodedLocation, userLocation)) return

        const { latitude, longitude } = userLocation

        const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`
        fetch(url, {
            method: 'GET',
            referrer: `${window.location.origin}`,
            headers: {
                'X-Requested-With': `${window.location.origin}`,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setUserAddress(data)
            })
            .catch((err) => {
                setUserAddress(null)
                console.error('Error getting address:', err)
            })
            .finally(() => {
                setPrevGeocodedLocation(userLocation)
            })
    }, [userLocation, currentMap])

    window.setStyle = setMapStyle

    // Create the context value object
    const mapContextValue = {
        mapContainer,
        currentMap,
        setCurrentMap,
        mapStyle,
        setMapStyle,
        showMap,
        setShowMap,
        loading,
        setLoading,
        startTracking,
        isTracking,
        locationError,
        locationLoading,
        userLocation,
        setUserLocation,
        userAddress,
    }

    return (
        <MapContext.Provider value={mapContextValue}>
            {props.children}
        </MapContext.Provider>
    )
}

export { MapProvider, MapContext }
