import React, { createContext, useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MapContext } from '@/context/MapContext'
import { DataContext } from '@/context/DataContext'

import maplibregl from 'maplibre-gl'

const RoutingContext = createContext()

// Memoized HOC to pass the map context to the wrapped component
const withParentContext = (WrappedComponent) => {
    const MemoizedComponent = React.memo(WrappedComponent)

    return function WithMapContext(props) {
        const { userLocation, startTracking, currentMap } =
            useContext(MapContext)
        const { selectedLocation } = useContext(DataContext)

        return (
            <MemoizedComponent
                {...props}
                selectedLocation={selectedLocation}
                userLocation={userLocation}
                startTracking={startTracking}
                currentMap={currentMap}
            />
        )
    }
}

const RoutingProvider = withParentContext(function RoutingProvider({
    selectedLocation,
    userLocation,
    startTracking,
    currentMap,
    children,
    ...rest
}) {
    const { t } = useTranslation()

    // Routing
    const [routeEditing, setRouteEditing] = useState(false)
    const [directions, setDirections] = useState(null)

    const [origin, setOrigin] = useState(null)
    const [destination, setDestination] = useState(null)

    const [originMode, setOriginMode] = useState('map') // 'map' or 'user-location'
    const [destinationMode, setDestinationMode] = useState('map') // 'map' or 'charging-location'

    const [addingOrigin, setAddingOrigin] = useState(false) // When true, the user is adding an origin
    const [addingDestination, setAddingDestination] = useState(false) // When true, the user is adding a destination

    const [routes, _setRoutes] = useState([])
    const [selectedRoute, _setSelectedRoute] = useState(null)

    const [directionsError, setDirectionsError] = useState(false)

    // Google maps
    const [gMapsUrl, setGMapsUrl] = useState('')

    // Initialization

    // When originMode == 'user-location', change origin to userLocation
    useEffect(() => {
        if (originMode !== 'user-location') return
        if (!userLocation) {
            startTracking()
            return
        }

        setOrigin([userLocation.longitude, userLocation.latitude])
    }, [originMode, userLocation])

    // When destinationMode == 'charging-location', change destination to selectedLocation
    useEffect(() => {
        if (destinationMode !== 'charging-location') return
        if (!selectedLocation) return

        setDestination(selectedLocation.geometry.coordinates)
    }, [destinationMode, selectedLocation])

    // When origin and destionation changes, create/update markers on the  map
    useEffect(() => {
        if (!currentMap) return

        let originMarker = null
        let destinationMarker = null

        if (origin) {
            // Add origin marker
            originMarker = new maplibregl.Marker({
                color: '#1f2937',
                draggable: false,
            })
                .setLngLat(origin)
                .addTo(currentMap)
                .on('dragend', (ev) => {
                    setOrigin([
                        ev.target.getLngLat().lng,
                        ev.target.getLngLat().lat,
                    ])
                })
        }

        if (destination) {
            // Add destination marker
            destinationMarker = new maplibregl.Marker({
                color: '#007cbf',
                draggable: false,
            })
                .setLngLat(destination)
                .addTo(currentMap)
                .on('dragend', (ev) => {
                    setDestination([
                        ev.target.getLngLat().lng,
                        ev.target.getLngLat().lat,
                    ])
                })
        }

        return () => {
            originMarker && originMarker.remove()
            destinationMarker && destinationMarker.remove()
        }
    }, [origin, destination])

    // When origin or destination changes, get the route if both are set
    useEffect(() => {
        if (!directions) return
        if (!origin || !destination) {
            setRouteEditing(false)
            setSelectedRoute(null)
            setRoutes([])
            directions.clear()
            directions.draw(false)
            return
        }
        getRoute({ origin, destination })
    }, [origin, destination])

    const formatDistance = (distance) => {
        return Math.round(distance / 1000)
    }

    const formatDuration = (duration) => {
        const hours = Math.floor(duration / 3600)
        const minutes = Math.round((duration - hours * 3600) / 60)

        let durationString = ''
        if (hours > 0) {
            durationString += hours + ' ' + t('ώρες') + ' '
        }
        if (minutes > 0) {
            durationString += minutes + ' ' + t('λεπτά') + ' '
        }

        return durationString
    }

    // Combine the origin and destination of each leg
    // from the waypoints array
    const addLegSourceDest = (legIdx, waypoints) => {
        const originPnt = waypoints[legIdx]
        const destPnt = waypoints[legIdx + 1]

        const origin = {
            name: originPnt.name
                ? originPnt.name
                : originPnt.location.join(','),
            location: originPnt.location,
        }
        const dest = {
            name: destPnt.name ? destPnt.name : destPnt.location.join(','),
            location: destPnt.location,
        }

        return { origin, dest }
    }

    // Internally set the routes array, after formatting
    const setRoutes = (routes, waypoints) => {
        // Format the distance and duration of each route in routes array
        const _routes = routes.map((route) => {
            const distance = formatDistance(route.distance)
            const duration = formatDuration(route.duration)

            const legs = waypoints
                ? route.legs.map((leg, index) => {
                      const { origin, dest } = addLegSourceDest(
                          index,
                          waypoints
                      )
                      return {
                          ...leg,
                          distance: formatDistance(leg.distance),
                          duration: formatDuration(leg.duration),
                          origin,
                          destination: dest,
                      }
                  })
                : null

            return {
                ...route,
                legs,
                distance,
                duration,
            }
        })

        _setRoutes(_routes)
    }

    const setSelectedRoute = (index) => {
        _setSelectedRoute(index)

        // Set the selected route to be drawn on the map
        directions.selectedRouteIndex = index
        directions.draw(false)
    }

    const clearRoute = () => {
        if (!directions) return
        directions.clear()
        setRoutes([])
        setSelectedRoute(null)
    }

    const _computeRoute = (origin, destination) => {
        if (!directions) return
        clearRoute()

        directions.setWaypoints([origin, destination])

        // Routing Completed
        directions.on('fetchroutesend', (ev) => {
            setRoutes(ev.data?.routes, ev.data?.waypoints)
            setSelectedRoute(0)
        })
    }

    const getRoute = ({ destination, origin }) => {
        try {
            _computeRoute(origin, destination)
        } catch (error) {
            setDirectionsError(true)
            console.log('Error setting waypoints')
        }
    }

    useEffect(() => {
        if (!directions) return
        directions.interactive = routeEditing
        directions.draw(false)
    }, [routeEditing])

    // Google maps
    useEffect(() => {
        if (!directions) return

        const getUrl = () => {
            const waypoints = directions.waypoints

            if (!waypoints || waypoints.length < 2) return

            const startLat = waypoints[0][1]
            const startLng = waypoints[0][0]
            const destLat = waypoints[waypoints.length - 1][1]
            const destLng = waypoints[waypoints.length - 1][0]
            const mapsURL = `https://www.google.com/maps/dir/${startLat},${startLng}/${destLat},${destLng}`
            return mapsURL
        }

        setGMapsUrl(getUrl())
    }, [selectedRoute, directions])

    // Create the context value object
    const routingContextValue = {
        origin,
        setOrigin,
        destination,
        setDestination,
        originMode,
        setOriginMode,
        destinationMode,
        setDestinationMode,
        addingOrigin,
        setAddingOrigin,
        addingDestination,
        setAddingDestination,
        routeEditing,
        setRouteEditing,
        directions,
        setDirections,
        clearRoute,
        routes,
        selectedRoute,
        setSelectedRoute,
        getRoute,
        directionsError,
        setDirectionsError,
        gMapsUrl,
    }

    return (
        <RoutingContext.Provider value={routingContextValue}>
            {children}
        </RoutingContext.Provider>
    )
})

export { RoutingProvider, RoutingContext }
