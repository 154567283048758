/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest'
import type { OpenAPIConfig } from './core/OpenAPI'
import { FetchHttpRequest } from './core/FetchHttpRequest'

import { ApiService } from './services/ApiService'
import { AuthService } from './services/AuthService'
import { ChargingCostService } from './services/ChargingCostService'
import { OcpiService } from './services/OcpiService'
import { VehiclesService } from './services/VehiclesService'

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest

export class CharginCostAPI {
    public readonly api: ApiService
    public readonly auth: AuthService
    public readonly chargingCost: ChargingCostService
    public readonly ocpi: OcpiService
    public readonly vehicles: VehiclesService

    public readonly request: BaseHttpRequest

    constructor(
        config?: Partial<OpenAPIConfig>,
        HttpRequest: HttpRequestConstructor = FetchHttpRequest
    ) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? window.location.origin, //'https://charging-cost.getmap.gr',
            VERSION: config?.VERSION ?? '.1',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        })

        this.api = new ApiService(this.request)
        this.auth = new AuthService(this.request)
        this.chargingCost = new ChargingCostService(this.request)
        this.ocpi = new OcpiService(this.request)
        this.vehicles = new VehiclesService(this.request)
    }
}
