import React, { useEffect, useState, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'

import LocationsList from '@/components/dashboard/DashboardListContainer'
import { EmptySearchPlaceholder } from '@/components/dashboard/EmptySearchPlaceholder'
import LocationDetails from '@/components/locations/LocationDetails.tsx'
import { SearchNameInput } from '@/components/dashboard/SearchName'

import { getScreenSize, isMobile } from '@/lib/utils'

import {
    getUniqueOperators,
    filterData,
    sortData,
} from '@/components/dashboard/dashboard_utils'

import { useTranslation } from 'react-i18next'

const DesktopLayout = React.lazy(() => import('./LayoutDesktop'))
const MobileLayout = React.lazy(() => import('./LayoutMobile'))

function PriceDashboardLayout({
    data,
    selectedLocation,
    setSelectedLocation,
    dataLoading,
    userLocation,
}) {
    const [showNullCost, setShowNullCost] = useState(true)
    const [panelExpanded, setPanelExpanded] = useState(true)
    const [selectedOperator, setSelectedOperator] = useState('') // State for selected operator filter
    const [searchText, setSearchText] = useState('') // State for search text
    const [sortingOption, setSortingOption] = useState('priceAscending') // State for sorting option
    const [screenSize, setScreenSize] = useState(getScreenSize())

    useEffect(() => {
        const handleResize = () => {
            setScreenSize(getScreenSize())
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    // Create a list of unique operators
    const operators = useMemo(() => getUniqueOperators(data), [data])

    const handleOperatorFilterChange = (e) => {
        setSelectedOperator(e.target.value)
    }

    const toggleShowNullCost = () => {
        setShowNullCost((prevShowNullCost) => !prevShowNullCost)
    }

    const handleSortingChange = (e) => {
        setSortingOption(e.target.value)
    }

    // Function to handle the panel collapse
    const handlePanelCollapse = (collapsed) => {
        setPanelExpanded(!collapsed)
    }

    // Filtering ----------------------------------------------------------------
    const filteredData = useMemo(
        () => filterData(data, selectedOperator, searchText, showNullCost),
        [data, selectedOperator, searchText, showNullCost]
    )

    // Sorting ------------------------------------------------------------------
    const sortedData = useMemo(
        () => sortData(filteredData, sortingOption),
        [filteredData, sortingOption]
    )

    const ControlBar = () => {
        const { t } = useTranslation()

        const totalLocations = data?.features ? data.features.length : 0
        const visibleLocations = sortedData ? sortedData.length : null
        return (
            <>
                <div className="text-sm text-secondary font-bold pt-3 px-3 hidden xl:block">
                    {t('Βρέθηκαν')} {sortedData ? sortedData.length : '-'}{' '}
                    {t('σταθμοί')}
                    {totalLocations !== visibleLocations ? (
                        <span className="text-gray-400">
                            {' '}
                            ({totalLocations - visibleLocations}{' '}
                            {t('κρυμμένοι')})
                        </span>
                    ) : null}
                </div>
                <div className="w-full flex flex-row pb-1 items-center px-3">
                    <FontAwesomeIcon
                        icon={faFilter}
                        className="mr-2 text-gray-400"
                    />
                    <select
                        value={sortingOption}
                        onChange={handleSortingChange}
                        className="select select-bordered select-xs max-w-xs"
                    >
                        <option value="priceAscending">
                            {t('Τιμή (Αύξουσα)')}
                        </option>
                        <option value="priceDescending">
                            {t('Τιμή (Φθίνουσα)')}
                        </option>
                        <option value="operatorAscending">
                            {t('Πάροχος (Α-Ω)')}
                        </option>
                        <option value="operatorDescending">
                            {t('Πάροχος (Ω-Α)')}
                        </option>
                        {userLocation ? (
                            <>
                                <option value="distanceAscending">
                                    {t('Απόσταση (Αύξουσα)')}
                                </option>
                                <option value="distanceDescending">
                                    {t('Απόσταση (Φθίνουσα)')}
                                </option>
                            </>
                        ) : null}
                    </select>

                    {/* Operator filter */}
                    <select
                        value={selectedOperator}
                        onChange={handleOperatorFilterChange}
                        className="select select-bordered select-xs max-w-xs ml-3"
                    >
                        <option value="">{t('Όλοι οι πάροχοι')}</option>
                        {operators.map((operator, index) => (
                            <option key={index} value={operator}>
                                {operator}
                            </option>
                        ))}
                    </select>
                    {/* Search location name */}
                    <SearchNameInput
                        onChange={setSearchText}
                        value={searchText}
                    />
                </div>
            </>
        )
    }

    const LocationsPanel = () => {
        return (
            <div className={'flex flex-col h-full gap-2 md:pt-[65px] px-2'}>
                {selectedLocation && !isMobile() ? (
                    <div className="overflow-y-auto transition-all duration-500 my-auto rounded-t-lg rounded-b-lg h-[100%] m-2 mt-3 mb-3  bg-base-200 bg-opacity-30">
                        <LocationDetails
                            onDashboard={true}
                            selectedLocation={selectedLocation}
                        />
                    </div>
                ) : (
                    <>
                        <ControlBar />
                        {!dataLoading &&
                        (!data || data?.features?.length === 0) ? (
                            <EmptySearchPlaceholder />
                        ) : null}

                        <LocationsList
                            sortedData={sortedData}
                            sortingOption={sortingOption}
                            setSelectedLocation={setSelectedLocation}
                        />
                    </>
                )}
            </div>
        )
    }

    if (isMobile())
        return (
            <React.Suspense fallback={<div>Loading...</div>}>
                <MobileLayout LocationsPanel={LocationsPanel} />
            </React.Suspense>
        )

    return (
        <React.Suspense fallback={<div>Loading...</div>}>
            <div className="h-full m-0 pb-[48px] pt-0  md:pb-0 overflow-y-hidden">
                <DesktopLayout
                    LocationsPanel={LocationsPanel}
                    handlePanelCollapse={handlePanelCollapse}
                    panelExpanded={panelExpanded}
                    screenSize={screenSize}
                />
            </div>
        </React.Suspense>
    )
}

export { PriceDashboardLayout }
