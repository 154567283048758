import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '@/context/AuthContext'
import { DataContext } from '@/context/DataContext'
import { MapContext } from '@/context/MapContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'

function RequestDataButton({ expanded, className, ...rest }) {
    const { t } = useTranslation()
    const { disclaimerConsent } = useContext(AuthContext)
    const { SearchLocations, dataLoading, paramsDidChange } =
        useContext(DataContext)
    const { locationLoading, currentMap } = useContext(MapContext)

    const getDisabled = () => {
        return (
            dataLoading ||
            locationLoading ||
            !paramsDidChange ||
            !currentMap ||
            !disclaimerConsent
        )
    }

    if (!expanded)
        return (
            <>
                <button
                    className={
                        'btn btn-sm btn-circle btn-secondary mx-auto tooltip tooltip-right' +
                        className
                    }
                    data-tip={t('Νέα Σύγκριση')}
                    onClick={SearchLocations}
                    disabled={getDisabled()}
                >
                    <FontAwesomeIcon icon={faMagnifyingGlass} className="" />
                </button>
            </>
        )

    return (
        <>
            <button
                className={
                    'btn btn-sm btn-secondary focus:ring ring-primary ' +
                    className
                }
                onClick={SearchLocations}
                disabled={getDisabled()}
            >
                <FontAwesomeIcon icon={faMagnifyingGlass} className="mr-2" />
                {t('Αναζήτηση')}
            </button>
        </>
    )
}

export default RequestDataButton
