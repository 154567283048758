import React from 'react'
import { NavLink } from 'react-router-dom'

import ChargingCostLogo from '@/assets/img/favicon.webp'

import RaaeyLogoGr from '@/assets/img/raaey_logo_gr.webp'
import RaaeyLogoEn from '@/assets/img/raaey_logo_en.webp'

function Logo(props) {
    const { children, overwriteChildren, link, text, ...rest } = props

    const RaeLogo = i18n.language === 'el' ? RaaeyLogoGr : RaaeyLogoEn

    return (
        <>
            <NavLink to={link} className="app-logo rounded-lg " {...rest}>
                <h1
                    className="text-3xl flex flex-row items-center "
                    style={{
                        letterSpacing: '-2px',
                    }}
                >
                    {!overwriteChildren ? (
                        <>
                            {/* <img src={RaeLogo} alt="" className="h-10 inline" /> */}
                            <img
                                src={ChargingCostLogo}
                                alt=""
                                className="h-7 inline"
                            />
                            <span className="">ChargingCost</span>
                        </>
                    ) : null}
                    {children}
                </h1>
            </NavLink>
        </>
    )
}

export default Logo
