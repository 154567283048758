import React, { useContext } from 'react'
import { DataContext } from '@/context/DataContext'
import { useTranslation } from 'react-i18next'
import {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    Transition,
} from '@headlessui/react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faChevronDown } from '@fortawesome/free-solid-svg-icons'

import { ConnectorIcon } from '@/components/connectors/ConnectorIcon'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ConnectorFormat({
    title,
    tooltip,
    parameter,
    choices,
    setParam,
    disabled = false,
    size = 'sm',
    onSidebar = false,
    infobox = false,
    className,
    ...rest
}) {
    const { t, i18n } = useTranslation()
    const data = useContext(DataContext)
    const parameterValue = data.params[parameter] // global state

    const parameterValueItem = choices.find(
        (item) => item.name === parameterValue
    )

    const lang = i18n.language

    const handleChange = (value) => {
        if (value === -1 || value === '-1' || !value) {
            setParam(parameter, null)
        } else {
            setParam(parameter, value)
        }
    }

    return (
        <div className="my-3">
            <Listbox
                value={parameterValue ? parameterValue : -1}
                onChange={handleChange}
            >
                {({ open }) => (
                    <>
                        <label className={`text-${size}`}>
                            &nbsp; {t('Τύπος Βύσματος')} &nbsp;
                            {/* Use t() to translate the label */}
                        </label>
                        <div className="relative">
                            <ListboxButton
                                className={`select select-${size} cursor-pointer relative w-full   bg-white pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1  ring-gray-300  sm:text-sm sm:leading-6`}
                                disabled={disabled}
                            >
                                <span className="flex items-center">
                                    {/* <img
                                    src={selected.avatar}
                                    alt=""
                                    className="h-5 w-5 flex-shrink-0 rounded-full"
                                /> */}
                                    {parameterValueItem ? (
                                        <ConnectorIcon
                                            connector_standard={
                                                parameterValueItem.name
                                            }
                                        />
                                    ) : null}
                                    <span
                                        className={`text-${size} block truncate`}
                                    >
                                        {parameterValueItem
                                            ? parameterValueItem[
                                                  `label_${lang}`
                                              ]
                                            : t('Όλα')}
                                    </span>
                                </span>
                                {/* <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                <FontAwesomeIcon icon={faChevronDown} />{' '}
                            </span> */}
                            </ListboxButton>

                            <Transition
                                show={open}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <ListboxOptions className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    <ListboxOption
                                        key={-1}
                                        value={-1}
                                        className={({ focus }) =>
                                            classNames(
                                                focus
                                                    ? 'bg-gray-300 text-gray-900'
                                                    : '',
                                                !focus ? 'text-gray-900' : '',
                                                'relative cursor-default select-none py-2 pl-3 pr-9'
                                            )
                                        }
                                    >
                                        {({ selected, focus }) => (
                                            <div className="flex items-center">
                                                <span
                                                    className={classNames(
                                                        selected
                                                            ? 'font-semibold'
                                                            : 'font-normal',
                                                        'ml-9 block truncate'
                                                    )}
                                                >
                                                    {t('Όλα')}
                                                </span>
                                                {selected ? (
                                                    <span className="text-primary absolute inset-y-0 right-0 flex items-center pr-4">
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </span>
                                                ) : null}
                                            </div>
                                        )}
                                    </ListboxOption>
                                    {choices &&
                                        choices.map((choice, index) => (
                                            <ListboxOption
                                                key={index}
                                                className={({ focus }) =>
                                                    classNames(
                                                        focus
                                                            ? 'bg-gray-300 text-gray-900'
                                                            : '',
                                                        !focus
                                                            ? 'text-gray-900'
                                                            : '',
                                                        'relative cursor-default select-none py-2 pl-3 pr-9'
                                                    )
                                                }
                                                value={choice.name}
                                            >
                                                {({ selected, focus }) => (
                                                    <>
                                                        <div className="flex items-center">
                                                            {/* <img
                                                            src={person.avatar}
                                                            alt=""
                                                            className="h-5 w-5 flex-shrink-0 rounded-full"
                                                        /> */}
                                                            <ConnectorIcon
                                                                connector_standard={
                                                                    choice.name
                                                                }
                                                            />
                                                            <span
                                                                className={classNames(
                                                                    selected
                                                                        ? 'font-semibold'
                                                                        : 'font-normal',
                                                                    'ml-3 block truncate'
                                                                )}
                                                            >
                                                                {
                                                                    choice[
                                                                        `label_${lang}`
                                                                    ]
                                                                }
                                                            </span>
                                                        </div>

                                                        {selected ? (
                                                            <span className="text-primary absolute inset-y-0 right-0 flex items-center pr-4">
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faCheck
                                                                    }
                                                                />
                                                            </span>
                                                        ) : null}
                                                    </>
                                                )}
                                            </ListboxOption>
                                        ))}
                                </ListboxOptions>
                            </Transition>
                        </div>
                    </>
                )}
            </Listbox>
        </div>
    )
}
