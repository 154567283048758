import React, { useContext } from 'react'

import { DataContext } from '@/context/DataContext'
import { Select } from 'react-daisyui'
import { useTranslation } from 'react-i18next'
import Modal from '@/components/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faXmark } from '@fortawesome/free-solid-svg-icons'

import { CircleButton } from '@/components/buttons/CircleButton'

function ExplainComponent({ title, choices, isOpen, setOpen }) {
    const { i18n } = useTranslation()
    const lang = i18n.language

    if (!choices) {
        return null
    }

    const hide = () => {
        setOpen(false)
    }

    return (
        <Modal isOpen={isOpen} onClose={hide}>
            <div className="md:bg-white md:border md:w-[60vw] min-h-[40vh] md:m-auto p-5 rounded-lg relative">
                {/* Header */}
                <div className="bg-white absolute top-0 left-0 right-0 p-3 flex flex-row w-full items-center rounded-t-lg pb-2 z-20 shadow">
                    <h3 className="font-bold">{title}</h3>
                    <CircleButton
                        // className="btn btn-outline btn-circle ml-auto"
                        onClick={hide}
                        icon={faXmark}
                    />
                </div>

                <div className="overflow-y-auto max-h-[80vh] pt-14">
                    <table className="table table-compact table-zebra">
                        <tbody>
                            {choices.map((choice, index) => {
                                return (
                                    <tr key={index}>
                                        <th className="bg-transparent">
                                            <h3>{choice[`label_${lang}`]}</h3>
                                        </th>
                                        <td className="bg-transparent">
                                            <p className="font-medium">
                                                {choice[`description_${lang}`]}
                                            </p>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </Modal>
    )
}

function AbstractChoicesFilter({
    title,
    tooltip,
    parameter,
    choices,
    setParam,
    disabled = false,
    size = 'sm',
    onSidebar = false,
    infobox = false,
    className,
    ...rest
}) {
    const { t, i18n } = useTranslation()
    const data = useContext(DataContext)
    const parameterValue = data.params[parameter] // global state
    const [showInfo, setShowInfo] = React.useState(false)

    const lang = i18n.language

    const handleChange = (value) => {
        if (value === -1 || value === '-1' || !value) {
            setParam(parameter, null)
        } else {
            setParam(parameter, value)
        }
    }

    return (
        <>
            {infobox ? (
                <ExplainComponent
                    title={title}
                    isOpen={showInfo}
                    setOpen={setShowInfo}
                    choices={choices}
                />
            ) : null}

            <div className="flex flex-col text-left content-center items-start rounded-lg p-2 px-0 cursor-pointer">
                <label className={`text-${size} text-left`}>
                    &nbsp; {title}
                    {infobox ? (
                        <button
                            className="btn btn-xs btn-ghost btn-circle rounded-full"
                            onClick={() => setShowInfo(true)}
                        >
                            <FontAwesomeIcon
                                icon={faInfoCircle}
                                className="text-info"
                            />
                        </button>
                    ) : null}
                </label>
                <div
                    className={`${
                        !onSidebar ? 'tooltip tooltip-top md:tooltip-right' : ''
                    } flex flex-row ${className ? className : 'w-48'}`}
                    data-tip={!onSidebar ? tooltip : null}
                >
                    <Select
                        className="w-full text-black"
                        value={parameterValue ? parameterValue : -1}
                        size={size}
                        onChange={(event) => handleChange(event.target.value)}
                        {...rest}
                        disabled={disabled}
                    >
                        <option value={-1}>{t('Όλα')}</option>

                        {choices &&
                            choices.map((choice, index) => {
                                return (
                                    <option key={index} value={choice.name}>
                                        {choice[`label_${lang}`]}
                                    </option>
                                )
                            })}
                    </Select>
                </div>
            </div>
        </>
    )
}

export { AbstractChoicesFilter }
