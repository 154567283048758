import React, {
    createContext,
    useState,
    useEffect,
    useContext,
    useMemo,
    useTransition,
    SetStateAction,
} from 'react'
import { useTranslation } from 'react-i18next'
import ILocation from '@/types/CharginStation'
import { IFeature, IFeatureCollection } from '@/types/Geojson'
import { AuthContext } from '@/context/AuthContext'
import { MapContext } from '@/context/MapContext'

import { shallowEqual, pointsDistance } from '@/lib/utils'

import { toast, Toaster } from 'sonner'
import type { CharginCostAPI } from '@/api/CharginCostAPI'

export interface ISearchFilters {
    lng: number
    lat: number
    srid?: number
    maxDistance?: number
    closestX?: number
    limit?: number
    company?: number
    isOpen?: boolean
    geojson?: boolean
    connectorType?: Array<string>
    connectorPowerTypes?: Array<string>
    connectorFormat?: Array<string>
    evseCapabilities?: Array<string>
    status?: Array<string>
    city?: string
    address?: string
    voltage?: number
    amperage?: number
    electricPower?: number
    asked_kwh?: number
    asked_euros?: number
    asked_duration?: number
    q?: string
    distanceFromUser?: number
}

const host = 'https://charging-cost.getmap.gr'
const api_host = 'https://charging-cost.getmap.gr/api'
const DataContext = createContext(null)

export const NULL_FILTERS: ISearchFilters = {
    lng: null,
    lat: null,
    srid: null,
    maxDistance: null,
    closestX: null,
    limit: null,
    company: null,
    isOpen: null,
    geojson: null,
    connectorType: null,
    connectorPowerTypes: null,
    connectorFormat: null,
    evseCapabilities: null,
    status: ['AVAILABLE'],
    city: null,
    address: null,
    voltage: null,
    amperage: null,
    electricPower: null,
    asked_kwh: 50,
    asked_euros: null,
    asked_duration: null,
    q: null,
    distanceFromUser: null,
}

const fetchData = (
    params: ISearchFilters,
    setData: {
        (value: SetStateAction<IFeatureCollection>): void
        (arg0: any): void
    },
    setDataLoading: {
        (value: SetStateAction<Boolean>): void
        (arg0: boolean): void
    },
    API: CharginCostAPI,
    userLocation: { latitude: any; longitude: any }
) => {
    const setExtendedData = (response) => {
        if (userLocation && response) {
            let dataWithDistance = { ...response }
            dataWithDistance.features = response.features.map((feature) => {
                const [lng, lat] = feature.geometry?.coordinates
                const distance = pointsDistance(
                    userLocation.latitude,
                    userLocation.longitude,
                    lat,
                    lng
                )

                return {
                    ...feature,
                    properties: {
                        ...feature.properties,
                        distanceFromUser: distance,
                    },
                }
            })
            setData(dataWithDistance ? dataWithDistance : response)
        } else {
            setData(response)
        }
    }
    setDataLoading(true)

    API.api
        .apiQueryPointsList(
            params.lng,
            params.lat,
            params.srid,
            params.maxDistance,
            params.closestX,
            params.limit,
            params.company,
            params.isOpen,
            params.geojson,
            params.connectorType,
            params.connectorPowerTypes,
            params.connectorFormat,
            params.evseCapabilities,
            params.status,
            params.city,
            params.address,
            params.voltage,
            params.amperage,
            params.electricPower,
            params.asked_kwh,
            params.asked_euros,
            params.asked_duration,
            params.q
        )
        .then((response) => {
            setExtendedData(response)
            setDataLoading(false)
        })
        .catch((error) => {
            console.log(error.body)
            setDataLoading(false)
        })
}

// Memoized HOC to pass the map context to the wrapped component
const withMapContext = (WrappedComponent) => {
    const MemoizedComponent = React.memo(WrappedComponent)

    return function WithMapContext(props) {
        const { userLocation } = useContext(MapContext)

        return <MemoizedComponent {...props} userLocation={userLocation} />
    }
}

const DataProvider = withMapContext(function DataProvider({
    userLocation,
    children,
    ...rest
}) {
    const { t } = useTranslation()
    // useTransition for fetchData
    const [isPending, startTransition] = useTransition()

    const { user, AuthenticatedAPI } = useContext(AuthContext)

    const [initialPageLoad, setInitialPageLoad] = useState(true)

    const [lastUpdated, setLastUpdated] = useState<Date>(null)

    const [data, setData] = useState<IFeatureCollection | null>(null)
    const [dataLoading, setDataLoading] = useState<Boolean>(false)

    const [selectedLocation, setSelectedLocation] = useState<IFeature>(null)
    const [selectedLocationDetails, setSelectedLocationDetails] =
        useState<ILocation[]>(null)

    const [params, _setParams] = useState<ISearchFilters>(NULL_FILTERS)
    const setParams = useMemo(
        // Only update if the params change
        () => (p) => {
            if (shallowEqual(params, p)) return
            _setParams(p)
        },
        [params]
    )

    const [paramsDidChange, setParamsDidChange] = useState<Boolean>(false) // track if params changed, to enable/disable the fetch button //trigger a new fetch

    // Use user's vehicle specs instead of manually applying them
    const [applyVehicle, setApplyVehicle] = useState(false)
    const [applyDistance, setApplyDistance] = useState(null) // initially null, to serve as a check of the first fetch of the user's location
    const [chargeMode, setChargeMode] = useState<'kwh' | 'euros' | 'minutes'>(
        'kwh'
    ) // kwh, euros, minutes

    const [firstPositionTrack, setFirstPositionTrack] = useState(true)

    useEffect(() => {
        fetch(`${host}/ocpi/last-update`)
            .then((response) => response.json())
            .then((data) => {
                setLastUpdated(new Date(data.updated))
            })
    }, [])
    // Every 4 minutes, retrieve the last update timestamp
    useEffect(() => {
        const interval = setInterval(() => {
            fetch(`${host}/ocpi/last-update`)
                .then((response) => response.json())
                .then((data) => {
                    setLastUpdated(new Date(data.updated))
                })
        }, 240000)
        return () => clearInterval(interval)
    }, [])

    // When data changes, remove selected location
    useEffect(() => {
        setSelectedLocation(null)
    }, [data])

    // If the user's vehicle is available, set the default charge amount, and apply vehicle
    useEffect(() => {
        if (user.profile === null || user.profile === undefined) {
            setApplyVehicle(false)
            return
        }
        if (user?.profile?.default_vehicle) {
            setApplyVehicle(true)
            setParams({
                ...params,
                asked_kwh: user.profile.default_vehicle.battery_capacity,
                asked_euros: null,
            })
        }
    }, [user.isAuthenticated])

    // Request data from the API
    useEffect(() => {
        setParamsDidChange(true)
    }, [params])

    // When the user changes, reset the params
    useEffect(() => {
        setParams(NULL_FILTERS)
    }, [user.isAuthenticated])

    // When the user location is available for the first time, set applyDistance to true
    useEffect(() => {
        if (userLocation && !applyDistance && firstPositionTrack) {
            setApplyDistance(true)
            setFirstPositionTrack(false)
        }
    }, [userLocation])

    // Query params setter
    // name: name of the query param (e.g. 'search', 'type')
    // value: value of the query param (e.g. 'searchValue', 'typeValue'
    function setQueryParam(name: string, value: any) {
        const newParams = { ...params }
        newParams[name] = value
        setParams(newParams)
    }

    function setQueryParamsMulti(paramArray: { name: string; value: any }[]) {
        const newParams = { ...params }

        paramArray.forEach((paramObj) => {
            newParams[paramObj.name] = paramObj.value
        })

        setParams(newParams)
    }

    // Get location details
    // If the selectedLocation changes, fetch the location details
    useEffect(() => {
        if (!selectedLocation) return
        const fetchDetailsData = async () => {
            const asked_kwh = params.asked_kwh
                ? `asked_kwh=${params.asked_kwh}`
                : ''
            const asked_euro = params.asked_euros
                ? `asked_euros=${params.asked_euros}`
                : ''
            const asked_duration = params.asked_duration
                ? `asked_duration=${params.asked_duration}`
                : ''

            try {
                const response = await fetch(
                    `${api_host}/location-charging-cost/${selectedLocation.id}/?${asked_kwh}&${asked_euro}&${asked_duration}`
                )
                if (!response.ok) {
                    throw new Error('Failed to fetch location details')
                }
                const data = await response.json()
                setSelectedLocationDetails(data)
            } catch (error) {
                console.error(error)
                setSelectedLocationDetails(null)
            }
        }
        fetchDetailsData()
    }, [selectedLocation])

    const SearchLocations = () => {
        setParamsDidChange(false)
        startTransition(() => {
            fetchData(
                params,
                setData,
                setDataLoading,
                AuthenticatedAPI,
                userLocation
            ) // debouncedFetchData
        })
    }

    useEffect(() => {
        if (dataLoading) {
            toast.loading(t('Αναζήτηση σταθμών...'), {
                position: 'top-center',
            })
        }
        if (!dataLoading) {
            toast.dismiss()
        }
    }, [dataLoading])

    const contextValue = {
        initialPageLoad: initialPageLoad,
        setInitialPageLoad: setInitialPageLoad,

        lastUpdated: lastUpdated,

        data: data,
        setData: setData,
        params: params,
        setParam: setQueryParam,
        setParamsMulti: setQueryParamsMulti,
        overwriteParams: setParams,

        applyVehicle: applyVehicle,
        setApplyVehicle: setApplyVehicle,
        applyDistance: applyDistance,
        setApplyDistance: setApplyDistance,
        chargeMode: chargeMode,
        setChargeMode: setChargeMode,

        dataLoading: dataLoading,
        setDataLoading: setDataLoading,
        selectedLocation: selectedLocation,
        setSelectedLocation: setSelectedLocation,
        selectedLocationDetails: selectedLocationDetails,

        paramsDidChange: paramsDidChange,
        setParamsDidChange: setParamsDidChange,
        SearchLocations: SearchLocations,
    }

    return (
        <DataContext.Provider value={contextValue}>
            {children}
            <Toaster />
        </DataContext.Provider>
    )
})
export { DataContext, DataProvider }
