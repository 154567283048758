import React, { useContext, useState, useEffect } from 'react'
import { DataContext } from '@/context/DataContext'
import { AuthContext } from '@/context/AuthContext'
import { AbstractChoicesFilter } from './AbstractFilters'
import { CapabilitiesFilter } from './CapabilitiesFilter'
import { ClearAll } from './ClearAll'

import ConnectorFormat from './ConnectorFormat'
import CompanyFilter from './CompanyFilter'

import { useTranslation } from 'react-i18next'

function ParamFilters({
    onSidebar = false,
    size = 'xs',
    explainCapabilities,
    className,
    ...rest
}) {
    const { AuthenticatedAPI } = useContext(AuthContext)
    const { setParam, applyVehicle } = useContext(DataContext)
    const [choices, setChoices] = useState(null)
    const { t } = useTranslation() // Initialize the translation function

    // Fetch choices from local storage if available when the component mounts
    useEffect(() => {
        const storedChoices = localStorage.getItem('choices')

        const choices = storedChoices ? JSON.parse(storedChoices) : null

        // TODO: this is a temporary fix to refresh the choices (if user has cached chioces from previous backend version)
        // if no timestamp, or timestamp before June 11 2024, refresh choices
        const refresh = choices
            ? !choices.timestamp ||
              choices.timestamp < Date.parse('11 Jun 2024 13:00:00 GMT')
            : false

        if (choices && !refresh) {
            setChoices(choices)
        } else {
            // Fetch choices from the API if not available in local storage
            AuthenticatedAPI.ocpi
                .ocpiDomainValuesList(
                    true, // tariffTypes
                    true, // tariffDimensions
                    true, // connectorStandards
                    true, // connectorFormats
                    true, // connectorPowerTypes
                    true, // evseStatuses
                    true, // evseParkingRestrictions
                    true // evseCapabilities
                )
                .then((apiChoices) => {
                    setChoices(apiChoices)

                    // Save choices in local storage
                    const storeChoices = {
                        ...apiChoices,
                        timestamp: Date.now(),
                    }
                    localStorage.setItem(
                        'choices',
                        JSON.stringify(storeChoices)
                    )
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }, [])

    if (!choices) return null

    return (
        <>
            <CompanyFilter onSidebar={true} size={size} />

            <ConnectorFormat
                title={t('Τύπος Βύσματος')}
                tooltip={t('Τύπος Βύσματος')}
                parameter="connectorType"
                choices={choices.connector_standards}
                setParam={setParam}
                size={size}
                disabled={applyVehicle}
                onSidebar={onSidebar}
                className={className}
                {...rest}
            />
            {/* <AbstractChoicesFilter
                title={t('Τύπος Βύσματος')}
                tooltip={t('Τύπος Βύσματος')}
                parameter="connectorType"
                choices={choices.connector_standards}
                setParam={setParam}
                size={size}
                disabled={applyVehicle}
                onSidebar={onSidebar}
                className={className}
                {...rest}
            /> */}
            <AbstractChoicesFilter
                title={t('Σύνδεση Ρευματοδότη')}
                tooltip={t('Καλώδιο/πρίζα')}
                parameter="connectorFormat"
                choices={choices?.connector_formats}
                setParam={setParam}
                size={size}
                className={className}
                onSidebar={onSidebar}
                {...rest}
            />
            <AbstractChoicesFilter
                title={t('Τύπος Ρεύματος')}
                tooltip={t('Τύπος Ρεύματος')}
                parameter="connectorPowerTypes"
                choices={choices?.connector_power_types}
                setParam={setParam}
                size={size}
                className={className}
                onSidebar={onSidebar}
                disabled={applyVehicle}
                {...rest}
            />

            <AbstractChoicesFilter
                title={t('Κατάσταση')}
                tooltip={t('Κατάσταση')}
                parameter="status"
                choices={choices?.evse_statuses}
                setParam={setParam}
                size={size}
                className={className}
                onSidebar={onSidebar}
                infobox={true}
                {...rest}
            />

            <CapabilitiesFilter
                title={t('Δυνατότητες')}
                tooltip={t('Δυνατότητες')}
                parameter="evseCapabilities"
                choices={choices?.evse_capabilities}
                setParam={setParam}
                size={size}
                className={className}
                onSidebar={onSidebar}
                {...rest}
            />
            {onSidebar ? <ClearAll /> : null}
        </>
    )
}

export { ParamFilters }
