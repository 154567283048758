import React, { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '@/context/AuthContext'
import { DataContext } from '@/context/DataContext'
import { MapContext } from '@/context/MapContext'
import RouteBase from '@/routes/RouteBase'
import { Disclaimer } from '@/components/Disclaimer'
import LanguageControl from '@/components/LanguageControl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ModalComponent from '@/components/Modal'
import { Toaster, toast } from 'sonner'
import {
    faAngleDoubleRight,
    faInfoCircle,
    faMagnifyingGlass,
    faLocationDot,
} from '@fortawesome/free-solid-svg-icons'

import UserVehiclesWidget from '@/components/accounts/UserVehiclesWidget'
import { ParamFilters } from '@/components/filters/ParamFilters'
import { ChargeAmount } from '@/components/filters/ChargeAmount'

/**
 * If a non-authenticated user accesses the dashboard for the first time,
 * he is presented with this screen, to initially set his search parameters
 *
 */
function ParameterWalkthrough() {
    const { t } = useTranslation()
    const { disclaimerConsent, setDisclaimerConsent } = useContext(AuthContext)
    const { setInitialPageLoad, SearchLocations } = useContext(DataContext)
    const { locationLoading } = useContext(MapContext)

    const [showModal, setShowModal] = useState(
        !disclaimerConsent || disclaimerConsent === 'false' ? true : false
    )

    const acceptDisclaimer = () => {
        setShowModal(false)
        setDisclaimerConsent(true)
    }

    const handleContinue = () => {
        setInitialPageLoad(false)
        SearchLocations()
    }

    // Show toast when user closes modal
    useEffect(() => {
        if (showModal) return
        toast(
            <div className="flex flex-row gap-3 items-center font-medium">
                <FontAwesomeIcon
                    icon={faLocationDot}
                    className="text-2xl m-3 opacity-50 text-blue-500"
                />

                <p>
                    {t(
                        'Για καλύτερα αποτελέσματα, επιτρέψτε στην εφαρμογή να έχει πρόσβαση στην τοποθεσία σας. Στη συνέχεια μπορείτε να ορίσετε την ακτίνα της αναζήτησης σας.'
                    )}
                    <br />
                    <span className="text-xs font-bold text-secondary">
                        {t('Η εφαρμογή δεν αποθηκεύει ποτέ την τοποθεσία σας.')}
                    </span>
                </p>
            </div>
        )
    }, [showModal])

    return (
        <>
            <ModalComponent
                isOpen={showModal}
                contentLabel={t('disclaimer.title')}
                onRequestClose={() => acceptDisclaimer(false)}
            >
                <div className="flex flex-col h-full w-full">
                    <div className="relative m-auto bg-white py-5 px-6 rounded-lg border shadow-xl flex flex-col">
                        <div className=" flex w-full justify-between">
                            <h2 className="  text-lg font-bold bg-white pb-2 pt-3 ">
                                {t('Αποποίηση Ευθύνης')}
                            </h2>
                            <LanguageControl />
                        </div>

                        <div className="overflow-y-auto max-h-[70vh] mt-7 pt-2 pb-4 border-t">
                            <Disclaimer />
                        </div>

                        <button
                            className="btn btn-secondary ml-auto"
                            onClick={() => acceptDisclaimer(false)}
                        >
                            {t(
                                'Έχω διαβάσει και αποδέχομαι την αποποίηση ευθύνης'
                            )}
                        </button>
                    </div>
                </div>
            </ModalComponent>
            <Toaster position="bottom-right" closeButton />
            <RouteBase classNames="flex flex-col overflow-x-hidden">
                <div className="m-auto h-max pt-14 pb-14 px-4 flex flex-col gap-4 items-start max-w-xl">
                    <h1 className="text-2xl font-bold ">
                        <FontAwesomeIcon
                            icon={faMagnifyingGlass}
                            className="mr-2"
                        />
                        {t('Νέα Σύγκριση')}
                    </h1>
                    <p className="font-medium">
                        {t(
                            'Επιλέξτε τα στοιχεία που ταιριάζουν στο όχημα σας για να δείτε κατάλληλους φορτιστές. Άν δεν είστε σίγουροι για τα τεχνικά χαρακτηριστικά του οχήματος σας, μπορείτε να τα αναζητήσετε στην ιστοσελίδα'
                        )}
                        {'  '}
                        <a
                            target="_blank"
                            href="https://www.ev-database.org/"
                            className="underline text-blue-700"
                        >
                            EV Database
                        </a>{' '}
                        .
                    </p>
                    <p>
                        - {t('Όταν είστε έτοιμοι, πατήστε το κουμπί')}{' '}
                        <span className="px-4 py-1 bg-secondary text-white font-bold rounded-lg text-sm mx-2">
                            {t('Σύγκριση Τιμών')} {'>>'}
                        </span>
                    </p>
                    <p className="">
                        <FontAwesomeIcon
                            icon={faInfoCircle}
                            className="opacity-60"
                        />{' '}
                        {t('Μπορείτε πάντα να χρησιμοποιήσετε την')}{' '}
                        <span className="font-bold">
                            {t('εργαλειοθήκη αριστερά')}
                        </span>{' '}
                        {t('για να κάνετε μια νέα αναζήτηση.')}
                    </p>

                    <div className="grid md:grid-cols-2 gap-7 mx-auto shadow-inner px-4 py-4 rounded-lg">
                        <ChargeAmount size="md" className="w-64 " />

                        <UserVehiclesWidget />
                        <ParamFilters size="md" className="w-64" />
                    </div>

                    <div className="w-64 mx-auto my-8 flex flex-row ">
                        <button
                            className="btn btn-secondary mr-auto"
                            onClick={handleContinue}
                            disabled={locationLoading || !disclaimerConsent}
                        >
                            {t('Σύγκριση Τιμών')} &nbsp;
                            <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </button>
                    </div>
                </div>
            </RouteBase>
        </>
    )
}

export { ParameterWalkthrough }
